import React, { useEffect, useMemo } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import { formatRecipeType, formatRecipeCountry } from '../../utils/recipes'
import { formatDuration } from '../../utils'
import {
  RecipeType,
  RecipeCountry,
  Recipe,
  Exact,
  GetRecipesQuery
} from '../../types/graphql'
import Table from '../shared/Table'
import { FaRegEdit } from 'react-icons/fa'
import { RiDeleteBin2Line } from 'react-icons/ri'
import { Button } from '../shared/Button'
import { useHistory } from 'react-router-dom'
import { QueryLazyOptions } from '@apollo/client'
import { capitalize } from 'lodash'
import Container from '../shared/Container'

interface Props {
  queryRecipesLoading: boolean
  recipes?: GetRecipesQuery
  getRecipes: (
    options?:
      | QueryLazyOptions<
          Exact<{
            first?: number | null | undefined
            after?: any
          }>
        >
      | undefined
  ) => void
  handleCreate: () => void
  handleUpdate: (recipe: Recipe) => void
  handleDelete: (recipe: Recipe) => void
}

const RecipeList: React.FC<Props> = props => {
  const history = useHistory()

  useEffect(() => {
    props.getRecipes()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const recipes = useMemo(() => {
    if (props.recipes) {
      return props.recipes.recipes.edges.map(edge => edge.node)
    }
  }, [props.recipes])

  const columns = [
    {
      Header: 'Nom',
      accessor: (row: Recipe) => row,
      Cell: ({ value }: { value: Recipe }) =>
        `${capitalize(value.name)}${
          value.original_name ? ` - ${value.original_name}` : ''
        }`
    },
    {
      Header: 'Type',
      accessor: 'type',
      disableSortBy: true,
      Cell: ({ value }: { value: RecipeType }) => formatRecipeType(value)
    },
    {
      Header: 'Pays',
      accessor: 'country',
      disableSortBy: true,
      Cell: ({ value }: { value: RecipeCountry }) => formatRecipeCountry(value)
    },
    {
      Header: 'Préparation',
      accessor: 'prep_duration',
      disableSortBy: true,
      Cell: ({ value }: { value: number }) => formatDuration(value)
    },
    {
      Header: 'Cuisson',
      accessor: 'cook_duration',
      disableSortBy: true,
      Cell: ({ value }: { value: number }) => formatDuration(value)
    },
    {
      Header: 'Actions',
      accessor: (row: Recipe) => row,
      disableSortBy: true,
      Cell: ({ value }: { value: Recipe }) => (
        <div className="space-x-1 flex">
          <Button
            small
            onClick={e => {
              e.stopPropagation()
              props.handleUpdate(value)
            }}
          >
            <FaRegEdit size={16} />
          </Button>
          <Button
            small
            onClick={e => {
              e.stopPropagation()
              props.handleDelete(value)
            }}
          >
            <RiDeleteBin2Line size={16} />
          </Button>
        </div>
      )
    }
  ]

  return (
    <Container>
      <div className="flex space-x-4">
        <Button primary onClick={() => props.handleCreate()}>
          Nouvelle recette
        </Button>
      </div>
      {recipes ? (
        <Table
          columns={columns}
          data={recipes}
          handleRowClick={row => {
            history.push(`/recipes/${(row.original as Recipe).id}`)
          }}
        />
      ) : (
        <div className="flex justify-center items-center h-full">
          <ClipLoader size={32} color="#E77F67" />
        </div>
      )}
    </Container>
  )
}

export default RecipeList
