import React from 'react'
import _ from 'lodash'
import Select, { Props as SelectProps } from 'react-select'
import { Control, Controller } from 'react-hook-form'

interface Props extends SelectProps {
  multiselect?: boolean
  isSearchable?: boolean
  items: {
    value: any
    label: string
  }[]
}
interface ControlledProps {
  control: Control
  rules?: {
    [key: string]: any
  }
}

const ControlledSelect: React.FC<Props & ControlledProps> = ({
  control,
  rules,
  name,
  items,
  placeholder,
  multiselect = false,
  isSearchable = false
}) => {
  return (
    <Controller
      name={name!}
      control={control}
      rules={rules}
      render={({ onChange, onBlur, value }) => {
        let formatValue = value
        if (!_.has(value, 'value') || !_.has(value, 'label')) {
          formatValue = items.find(item => item.value === value)
        }
        return (
          <CustomSelect
            isSearchable={isSearchable}
            multiselect={multiselect}
            items={items}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={onChange}
            value={formatValue}
          />
        )
      }}
    />
  )
}

const CustomSelect: React.FC<Props> = ({
  items,
  placeholder,
  isSearchable = false,
  multiselect,
  ...rest
}) => {
  return (
    <Select
      className="w-full font-body text-xs text-gray-900"
      isSearchable={isSearchable}
      isMulti={multiselect}
      options={items}
      placeholder={placeholder}
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: '#FEEBC8',
          primary: '#ED8936'
        }
      })}
      styles={{
        input: input => ({
          ...input,
          margin: 0,
          padding: 0
        }),
        control: control => ({
          ...control,
          minHeight: 0,
          minWidth: 120,
          borderColor: '#E2E8F0',
          boxShadow: 'none',
          '&:hover': {
            borderColor: '#ED8936'
          },
          padding: '0.5rem 1rem'
        }),
        indicatorSeparator: separator => ({
          ...separator,
          display: 'none'
        }),
        clearIndicator: container => ({
          ...container,
          padding: 0,
          width: 18,
          height: 18
        }),
        dropdownIndicator: container => ({
          ...container,
          padding: 0,
          width: 18,
          height: 18
        }),
        valueContainer: val => ({
          ...val,
          padding: 0
        })
      }}
      {...rest}
    />
  )
}

export { ControlledSelect, CustomSelect }
