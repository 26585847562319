import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { allergeneToString, dietToString } from '../../utils/ingredients'
import {
  Allergene,
  CreateIngredient,
  UpdateIngredient,
  Diet
} from '../../types/graphql'
import { useForm } from 'react-hook-form'
import FormItem from '../shared/FormItem'
import Input from '../shared/Input'
import { Button } from '../shared/Button'
import Modal from '../shared/Modal'
import CheckboxGroup from '../shared/CheckboxGroup'
import {
  cleanObjectValues,
  requiredRule,
  stringLenRule
} from '../../utils/form'

interface Props {
  initialValues: UpdateIngredient | null
  visible: boolean
  submitting: boolean
  onCreate: (values: CreateIngredient) => void
  onUpdate: (values: UpdateIngredient) => void
  onCancel: (changed: boolean) => void
}

const IngredientCreation: React.FC<Props> = ({
  initialValues,
  visible,
  submitting,
  onCancel,
  onCreate,
  onUpdate
}) => {
  const [formInitialValues, setFormInitialValues] = useState<
    Omit<UpdateIngredient, 'id'>
  >({})
  const { register, errors, handleSubmit, getValues, reset } = useForm<
    Omit<UpdateIngredient, 'id'>
  >()

  useEffect(() => {
    let values: Omit<UpdateIngredient, 'id'> = {}
    if (initialValues && visible) {
      // initialvalues cleaned
      const cleanedValues = cleanObjectValues(initialValues)
      values = { ...cleanedValues, name: _.capitalize(cleanedValues.name!) }
    }
    reset(values)
    setFormInitialValues(values)
  }, [initialValues, visible, reset])

  const onSubmit = handleSubmit(async values => {
    const cleaned = cleanObjectValues(values)
    try {
      if (initialValues != null) {
        onUpdate({ id: initialValues.id, ...cleaned })
      } else {
        onCreate(cleaned as CreateIngredient)
      }
    } catch (error) {}
  })

  const handleCancel = () => {
    if (!submitting) {
      onCancel(
        !_.isEqualWith(
          cleanObjectValues(formInitialValues, true),
          cleanObjectValues(getValues(), true),
          (obj: any, oth: any) => {
            if (_.isArray(obj) && _.isArray(oth)) {
              return _.isEqual(_.sortBy(obj), _.sortBy(oth))
            }
            return undefined
          }
        )
      )
    }
  }

  return (
    <Modal
      visible={visible}
      title={
        initialValues != null ? 'Modifier un ingrédient' : 'Nouvel ingrédient'
      }
      onClose={() => handleCancel()}
    >
      <form className="space-y-4" onSubmit={onSubmit}>
        <FormItem required label="Nom de l'ingrédient" error={errors.name}>
          <Input
            register={register({ ...requiredRule(), ...stringLenRule(3, 50) })}
            name="name"
            placeholder="Ex: Farine de riz gluant"
          />
        </FormItem>
        <FormItem label="Lien vers l'image">
          <Input
            register={register}
            name="img_url"
            placeholder="Ex: https://mukbox-recipes.s3.eu-west-3.amazonaws.com/ingredients/carrot.svg"
          />
        </FormItem>
        <FormItem label="Allergènes">
          <CheckboxGroup
            register={register}
            name="allergenes"
            items={Object.values(Allergene).map(all => ({
              value: all,
              label: allergeneToString(all)
            }))}
          />
        </FormItem>
        <FormItem label="Incompatible">
          <CheckboxGroup
            name="diet_constraints"
            register={register}
            items={Object.values(Diet).map(diet => ({
              value: diet,
              label: dietToString(diet)
            }))}
          />
        </FormItem>
        <div className="space-x-2 flex justify-end">
          <Button onClick={() => handleCancel()}>Annuler</Button>
          <Button loading={submitting} submit>
            Enregistrer
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default IngredientCreation
