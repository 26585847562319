import React, { useEffect, useState, useMemo } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import {
  allergeneToString,
  getAllergeneColor,
  getDietColor,
  dietToString
} from '../../utils/ingredients'
import {
  Ingredient,
  Allergene,
  GetIngredientsQuery,
  Exact,
  Diet
} from '../../types/graphql'
import _ from 'lodash'
import { Button } from '../shared/Button'
import Tag from '../shared/Tag'
import { QueryLazyOptions } from '@apollo/client'
import Input from '../shared/Input'
import Table from '../shared/Table'
import { FaRegEdit } from 'react-icons/fa'
import { GoSearch } from 'react-icons/go'
import { RiDeleteBin2Line } from 'react-icons/ri'
import Container from '../shared/Container'

interface Props {
  queryIngredientsLoading: boolean
  getIngredients: (
    options?:
      | QueryLazyOptions<
          Exact<{
            first?: number | null | undefined
            after?: any
          }>
        >
      | undefined
  ) => void
  handleCreate: () => void
  handleUpdate: (ingredient: Ingredient) => void
  handleDelete: (ingredient: Ingredient) => void
  ingredients?: GetIngredientsQuery
}

const IngredientList: React.FC<Props> = props => {
  const [searchStr, setSearchStr] = useState('')

  useEffect(() => {
    props.getIngredients()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const initialList = useMemo(() => {
    if (props.ingredients) {
      return props.ingredients?.ingredients.edges.map(edge => edge.node)
    }
  }, [props.ingredients])
  const filtered = useMemo(() => {
    if (initialList) {
      if (searchStr.length >= 1) {
        const filtered = initialList.filter(ing =>
          ing.name.toLowerCase().includes(searchStr.toLowerCase())
        )
        return filtered
      } else {
        return initialList
      }
    }
    return []
  }, [searchStr, initialList])

  const columns = [
    {
      accessor: 'img_url',
      Cell: ({ value }: { value: string }) => (
        <div className="w-6 flex justify-center">
          <img
            loading="lazy"
            className="w-full"
            src={value}
            crossOrigin="anonymous"
            alt=""
          />
        </div>
      ),
      disableSortBy: true
    },
    {
      Header: 'Nom',
      accessor: 'name',
      Cell: ({ value }: { value: string }) => _.capitalize(value)
    },
    {
      Header: 'Allergènes',
      accessor: 'allergenes',
      Cell: ({ value }: { value: Allergene[] | null }) => {
        if (value) {
          return (
            <div className="flex flex-wrap">
              {value.map((allergene, i) => (
                <div key={i} className="m-1">
                  <Tag color={getAllergeneColor(allergene)}>
                    {allergeneToString(allergene)}
                  </Tag>
                </div>
              ))}
            </div>
          )
        }
        return null
      },
      disableSortBy: true
    },
    {
      Header: 'Incompatibles',
      accessor: 'diet_constraints',
      Cell: ({ value }: { value: Diet[] | null }) => {
        if (value) {
          return (
            <div className="flex flex-wrap">
              {value.map((diet, i) => (
                <div key={i} className="m-1">
                  <Tag color={getDietColor(diet)}>{dietToString(diet)}</Tag>
                </div>
              ))}
            </div>
          )
        }
        return null
      },
      disableSortBy: true
    },
    {
      Header: 'Actions',
      accessor: (row: Ingredient) => row,
      Cell: ({ value }: { value: Ingredient }) => {
        if (value) {
          return (
            <div className="space-x-1 flex">
              <Button small onClick={() => props.handleUpdate(value)}>
                <FaRegEdit size={16} />
              </Button>
              <Button small onClick={() => props.handleDelete(value)}>
                <RiDeleteBin2Line size={16} />
              </Button>
            </div>
          )
        }
        return null
      },
      disableSortBy: true
    }
  ]

  return (
    <Container>
      <div className="flex space-x-4">
        <div className="flex-shrink-0">
          <Button
            primary
            onClick={() => {
              props.handleCreate()
            }}
          >
            Nouvel ingrédient
          </Button>
        </div>
        <Input
          placeholder="Rechercher..."
          onChange={e => setSearchStr(e.currentTarget.value)}
          suffix={
            <div className="px-3">
              <GoSearch size={16} />
            </div>
          }
        />
      </div>
      {filtered ? (
        <Table columns={columns} data={filtered} />
      ) : (
        <div className="flex justify-center items-center h-full">
          <ClipLoader size={32} color="#E77F67" />
        </div>
      )}
    </Container>
  )
}

export default IngredientList
