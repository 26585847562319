import _ from 'lodash'

export const cleanObjectValues = (values: any, removeEmptyArray?: boolean) => {
  const clone = _.clone(values)
  _.forIn(clone, (value, key) => {
    if (key === '__typename' || key === 'id') {
      _.unset(clone, key)
    }
    if (
      value === '' ||
      value == null ||
      (removeEmptyArray && value.length === 0)
    ) {
      _.unset(clone, key)
    }
    if (_.isObject(value) && Object.keys(value).length > 0) {
      if (_.has(value, 'value') && _.has(value, 'label')) {
        clone[key] = (value as any).value
      } else {
        clone[key] = cleanObjectValues(value, removeEmptyArray)
      }
    }
  })
  return clone
}

export const requiredRule = () => {
  return {
    required: 'Champ requis.'
  }
}

export const stringLenRule = (min: number, max: number) => {
  return {
    minLength: { value: min, message: `Au moins ${min} caractères` },
    maxLength: { value: max, message: `Pas plus de ${max} caractères` }
  }
}
