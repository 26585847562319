import React, { useState, useEffect } from 'react'
import { onError } from '@apollo/client/link/error'
import Main from './components/Main'
import ClipLoader from 'react-spinners/ClipLoader'
import * as firebase from 'firebase/app'
import * as Sentry from '@sentry/react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import LoginForm from './components/LoginForm'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink
} from '@apollo/client'
import { setContext } from '@apollo/link-context'

interface Props {}

const PrivateRoute = ({ component, user, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={props =>
        user ? (
          React.createElement(component, { ...props })
        ) : (
          <Redirect to={{ pathname: '/login' }} />
        )
      }
    />
  )
}

const App: React.FC<Props> = props => {
  const [user, setUser] = useState<firebase.User | null>(null)
  const [apolloClient, setApolloClient] = useState<ApolloClient<any>>()
  const [isInitializing, setInitializing] = useState(true)

  const onAuthStateChange = () => {
    return firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        setUser(user)
      }
      setInitializing(false)
    })
  }
  useEffect(() => {
    const unsubscribe = onAuthStateChange()
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(graphQLError =>
            Sentry.captureException(graphQLError)
          )
        }
        if (networkError) {
          console.log(networkError)
          Sentry.captureException(networkError)
        }
      })
      let authLink: any
      const httpLink = createHttpLink({
        uri:
          process.env.NODE_ENV === 'production'
            ? `${process.env.REACT_APP_ENDPOINT_URL}/graphql-proxy`
            : 'http://localhost:3000/dev/mukbox/graphql-proxy'
      })
      if (user) {
        const token = await user.getIdToken()
        authLink = setContext((_, { headers }) => {
          return {
            headers: {
              ...headers,
              authorization: token ? `Bearer ${token}` : ''
            }
          }
        })
      }
      const client = new ApolloClient({
        cache: new InMemoryCache(),
        link: errorLink.concat(authLink ? authLink.concat(httpLink) : httpLink)
      })
      setApolloClient(client)
    })()
  }, [isInitializing, user])

  if (isInitializing || apolloClient == null) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ClipLoader size={32} color="#E77F67" />
      </div>
    )
  }

  return (
    <ApolloProvider client={apolloClient}>
      <Router>
        <Switch>
          <Route
            path="/login"
            render={props => <LoginForm {...props} user={user} />}
          />
          <PrivateRoute path="/" component={Main} user={user} />
        </Switch>
      </Router>
    </ApolloProvider>
  )
}

export default App
