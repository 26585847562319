import React, { useMemo } from 'react'
import _ from 'lodash'
import { GetRecipesFullInfoQuery, IngredientQty } from '../../../types/graphql'
import { ReactComponent as IngredientsIcon } from '../../../assets/svg/ingredients.svg'
import { ReactComponent as AllergenesIcon } from '../../../assets/svg/allergenes.svg'
import { ReactComponent as GoodStartIcon } from '../../../assets/svg/good-start.svg'
import { formatIngredientUnit } from '../../../utils/recipes'
import styles from './Verso.module.css'
import BlockSubtitle from './BlockSubtitle'
import BulletList from './BulletList'
import { allergeneToString } from '../../../utils/ingredients'
import PreparationSteps from './PreparationSteps'
import DataList from './DataList'

interface Props {
  recipe: GetRecipesFullInfoQuery
}

const Verso: React.FC<Props> = ({ recipe }) => {
  const recipeData = useMemo(() => recipe.recipesFiltered[0], [recipe])

  const formatIngredientsTable = (ingQty: IngredientQty[]) => {
    return ingQty.map(ing => {
      const arr = []
      arr.push(
        `${_.capitalize(ing.ingredient.name)} (${formatIngredientUnit(
          ing.unit
        )})`
      )
      arr.push(`${ing.quantity.box_two}`)
      arr.push(ing.quantity.box_four ? `${ing.quantity.box_four}` : '')
      arr.push(ing.quantity.box_six ? `${ing.quantity.box_six}` : '')
      return arr
    })
  }
  return (
    <div className="flex absolute w-full h-full left-0 top-0">
      <div className={styles.extrasWrapper}>
        <div className={styles.block}>
          <BlockSubtitle label="Bien démarrer" Icon={GoodStartIcon} />
          {recipeData.good_start?.split('\n').map((str, i) => (
            <p className={styles.goodStart} key={i}>
              {str}
            </p>
          ))}
        </div>
        <div className={styles.block}>
          <BlockSubtitle label="Ingrédients" Icon={IngredientsIcon} />
          <DataList
            header={['', '2P', '4P', '6P']}
            rows={formatIngredientsTable(
              recipeData.ingredients as IngredientQty[]
            )}
            colSize={[5, 1, 1, 1]}
          />
        </div>
        <div className={styles.block}>
          <BlockSubtitle label="Allergènes" Icon={AllergenesIcon} />
          <BulletList
            items={_.chain(recipeData.ingredients)
              .map(ing => ing.ingredient.allergenes || [])
              .flattenDeep()
              .map(ing => allergeneToString(ing) as string)
              .uniq()
              .value()}
          />
        </div>
      </div>
      <div className={styles.preparationWrapper}>
        {recipeData.steps && <PreparationSteps items={recipeData.steps} />}
      </div>
    </div>
  )
}

export default Verso
