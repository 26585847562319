import React from 'react'

interface Props {
  register?: any
  name: string
  items: {
    value: string
    label: string
  }[]
}

interface CBProps extends React.HTMLProps<HTMLInputElement> {
  register?: any
}

const Checkbox: React.FC<CBProps> = ({ register, label, value, name }) => {
  return (
    <div className="w-1/4">
      <label className="inline-flex items-center">
        <input
          ref={register || undefined}
          className="form-checkbox h-5 w-5 text-orange-500 focus:shadow-none focus:border-orange-500"
          type="checkbox"
          name={name}
          value={value}
        />
        <span className="ml-2 font-body text-gray-900 text-xs">{label}</span>
      </label>
    </div>
  )
}

const CheckboxGroup: React.FC<Props> = ({ register, name, items }) => {
  return (
    <div className="flex flex-wrap">
      {items.map(item => (
        <Checkbox
          key={item.value}
          value={item.value}
          label={item.label}
          name={name}
          register={register}
        />
      ))}
    </div>
  )
}

export default CheckboxGroup
