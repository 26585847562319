import { gql } from '@apollo/client'

export const GET_INGREDIENTS = gql`
  query GetIngredients($first: Int, $after: DateTime) {
    ingredients(first: $first, after: $after) {
      edges {
        node {
          id
          name
          img_url
          allergenes
          diet_constraints
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`

export const CREATE_INGREDIENT = gql`
  mutation CreateIngredient($create: CreateIngredient!) {
    createIngredient(create: $create) {
      id
      name
      img_url
      allergenes
      diet_constraints
      created_at
    }
  }
`

export const UPDATE_INGREDIENT = gql`
  mutation UpdateIngredient($update: UpdateIngredient!) {
    updateIngredient(update: $update) {
      id
      name
      img_url
      allergenes
      diet_constraints
    }
  }
`

export const DELETE_INGREDIENT = gql`
  mutation DeleteIngredient($id: ID!) {
    deleteIngredient(id: $id)
  }
`
