const hexToRgb = (hex: string) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  const _hex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(_hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null
}

export const computeTextColorFromBgColor = ({
  bgColor,
  dark = 'black',
  light = 'white'
}: {
  bgColor: string
  dark?: string
  light?: string
}) => {
  const rgb = hexToRgb(bgColor)
  if (rgb) {
    const brightness = Math.round(
      (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000
    )
    return brightness > 125 ? dark : light
  }
  return dark
}

export const formatDuration = (duration: number, unit = 'min') => {
  if (!duration) {
    return ''
  }
  switch (unit) {
    case 'min':
      const h = Math.floor(duration / 60)
      const m = duration - h * 60
      return `${h > 0 ? `${h}h` : ''}${m > 9 ? m : `0${m}`}${
        m > 0 ? 'min' : ''
      }`

    default:
      return ''
  }
}

export const getEventColor = (
  event: 'error' | 'warning' | 'success' | 'info'
) => {
  switch (event) {
    case 'error':
      return 'red'
    case 'warning':
      return 'orange'
    case 'success':
      return 'green'
    case 'info':
      return 'teal'
    default:
      return ''
  }
}
