import React from 'react'
import { FieldError } from 'react-hook-form'

interface Props {
  required?: boolean
  label?: string
  error?: FieldError
}

const FormItem: React.FC<Props> = ({ required, label, error, children }) => {
  return (
    <div>
      {label && (
        <p className="font-body font-semibold text-xs text-gray-700 mb-1">
          {label}
          {required && <span className="text-orange-500 ml-1">*</span>}
        </p>
      )}

      {children}
      {error && error.message && (
        <p className="w-full font-body text-xs text-red-500">{error.message}</p>
      )}
    </div>
  )
}

export default FormItem
