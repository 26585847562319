import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs'
import { GetRecipesFullInfoQuery } from '../../../types/graphql'
import Modal from '../../shared/Modal'
import { Button } from '../../shared/Button'
import Recto from './Recto'
import Verso from './Verso'
import styles from './index.module.css'
import Page from './Page'

interface Props {
  recipe: GetRecipesFullInfoQuery
  visible: boolean
  onClose: () => void
}

const RecipeCard: React.FC<Props> = ({ recipe, visible, onClose }) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [activeTab, setActiveTab] = useState(0)
  const [isExporting, setExporting] = useState(false)

  useEffect(() => {
    const debounceHandler = _.debounce(() => {
      setWindowSize(window.innerWidth)
    }, 500)
    window.addEventListener('resize', debounceHandler)
    return () => {
      window.removeEventListener('resize', debounceHandler)
    }
  }, [])

  const handleExport = async () => {
    setExporting(true)
    setActiveTab(0)
    await Promise.resolve(setTimeout(() => {}, 1000))
    const pdf = new jsPDF({ orientation: 'landscape', compress: true })
    const width = pdf.internal.pageSize.getWidth()
    const height = pdf.internal.pageSize.getHeight()
    const rectoEl = document.getElementById('recipe-recto')!
    const rectoCanvas = await html2canvas(rectoEl, {
      scale: 3,
      useCORS: true,
      width: 1169,
      height: 827
    })
    const rectoPNG = rectoCanvas.toDataURL('image/png')
    pdf.addImage(rectoPNG, 'PNG', 0, 0, width, height)
    setActiveTab(1)
    await Promise.resolve(setTimeout(() => {}, 1000))
    const versoEl = document.getElementById('recipe-verso')!
    const versoCanvas = await html2canvas(versoEl, {
      scale: 3,
      useCORS: true,
      width: 1169,
      height: 827
    })
    const versoPNG = versoCanvas.toDataURL('image/png')
    pdf.addPage()
    pdf.addImage(versoPNG, 'PNG', 0, 0, width, height)
    pdf.save(`${recipe.recipesFiltered[0].name}.pdf`)
    setExporting(false)
  }

  return (
    <Modal
      visible={visible}
      width={windowSize * 0.8 > 1240 ? 1240 : windowSize * 0.8}
      onClose={() => {
        if (!isExporting) {
          onClose()
        }
      }}
    >
      <div className="space-y-4">
        <Tabs
          selectedIndex={activeTab}
          onSelect={tabIndex => setActiveTab(tabIndex)}
        >
          <TabList className="flex font-title text-sm text-gray-700">
            <Tab className="rounded-tl-md px-4 py-2 border border-b-0 border-gray-300 bg-gray-100 cursor-pointer">
              Recto
            </Tab>
            <Tab className="rounded-tr-md px-4 py-2 border border-b-0 border-gray-300 bg-gray-100 cursor-pointer">
              Verso
            </Tab>
          </TabList>
          <TabPanel
            className={`${activeTab === 0 && 'p-4 border border-gray-300'}`}
          >
            <div className={styles.container}>
              <Page id="recipe-recto">
                <Recto recipe={recipe} />
              </Page>
            </div>
          </TabPanel>
          <TabPanel
            className={`${activeTab === 1 && 'p-4 border border-gray-300'}`}
          >
            <div className={styles.container}>
              <Page id="recipe-verso" verso>
                <Verso recipe={recipe} />
              </Page>
            </div>
          </TabPanel>
        </Tabs>
        <div className="flex justify-end">
          <Button loading={isExporting} primary onClick={() => handleExport()}>
            Exporter en PDF
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default RecipeCard
