import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { ReactComponent as Logo } from '../assets/svg/logo-dark.svg'
import * as firebase from 'firebase/app'
import { RouteComponentProps } from 'react-router-dom'
import Input from './shared/Input'
import { displayNotification } from './Notification'
import FormItem from './shared/FormItem'
import { requiredRule } from '../utils/form'
import { Button } from './shared/Button'

interface Props extends RouteComponentProps {
  user: firebase.User | null
}

type FormData = {
  email: string
  password: string
}

const LoginForm: React.FC<Props> = props => {
  const { register, errors, handleSubmit } = useForm<FormData>()
  const [connecting, setConnecting] = useState(false)

  const onSubmit = handleSubmit(async values => {
    setConnecting(true)
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(values.email, values.password)
    } catch (error) {
      displayNotification(
        'error',
        'Connection error',
        error.message || 'Unknown error'
      )
    } finally {
      setConnecting(false)
    }
  })

  if (props.user) {
    props.history.replace({ pathname: '/' })
    return null
  }

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="w-9/12 max-w-md bg-white p-8 rounded-md shadow">
        <Logo className="mx-auto" width="116" height="20" />
        <form className="mt-8 space-y-4" onSubmit={onSubmit}>
          <FormItem error={errors.email}>
            <Input
              register={register(requiredRule())}
              name="email"
              placeholder="Adresse email"
            />
          </FormItem>
          <FormItem error={errors.password}>
            <Input
              register={register(requiredRule())}
              name="password"
              placeholder="Mot de passe"
              type="password"
            />
          </FormItem>
          <div className="flex justify-center">
            <Button loading={connecting} submit>
              Connexion
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default LoginForm
