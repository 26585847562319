import React from 'react'
import _ from 'lodash'
import { Controller, Control } from 'react-hook-form'

interface Props extends React.HTMLProps<HTMLInputElement> {
  control: Control
  rules?: {
    [key: string]: any
  }
  containerWidth?: string
}

const InputNumber: React.FC<Props> = ({
  control,
  containerWidth,
  rules,
  name,
  step,
  ...rest
}) => {
  return (
    <Controller
      name={name!}
      control={control}
      rules={rules}
      render={({ onChange, onBlur, value }) => (
        <div className={`flex ${containerWidth && containerWidth}`}>
          <input
            className="rounded w-full px-4 py-2 font-body text-xs text-gray-900 outline-none border border-gray-300 focus:border-orange-500 transition-all duration-300"
            type="number"
            step={step || 'any'}
            onChange={e =>
              onChange(
                e.target.value !== ''
                  ? _.toNumber(e.target.value)
                  : e.target.value
              )
            }
            onBlur={onBlur}
            value={value}
            {...rest}
          />
        </div>
      )}
    />
  )
}

export default InputNumber
