import React, { useState, useEffect, useMemo } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import { QueryLazyOptions } from '@apollo/client'
import {
  Exact,
  GetRecipesFullInfoQuery,
  RecipeFilter
} from '../../types/graphql'
import { useParams } from 'react-router-dom'
import RecipeCard from './RecipeCard'
import { capitalize } from 'lodash'
import { Button } from '../shared/Button'
import Alert from '../shared/Alert'
import Container from '../shared/Container'

interface Props {
  setBreadcrumbRecipeName: (name: string) => void
  queryRecipeLoading: boolean
  getRecipeInfo: (
    options?:
      | QueryLazyOptions<
          Exact<{
            filter: RecipeFilter
          }>
        >
      | undefined
  ) => void
  handleUpdate: () => void
  recipe?: GetRecipesFullInfoQuery
}

const RecipeDetail: React.FC<Props> = ({
  setBreadcrumbRecipeName,
  getRecipeInfo,
  queryRecipeLoading,
  recipe,
  handleUpdate
}) => {
  const { recipeId } = useParams()
  const [cardVisible, setCardVisibility] = useState(false)

  useEffect(() => {
    getRecipeInfo({ variables: { filter: { ids: [recipeId] } } })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (recipe) {
      setBreadcrumbRecipeName(capitalize(recipe.recipesFiltered[0].name))
    }
  }, [recipe]) // eslint-disable-line react-hooks/exhaustive-deps

  const stepsIllustrated = useMemo(() => {
    if (recipe != null) {
      if (
        recipe!.recipesFiltered[0].steps?.find(
          step => step.img != null && step.img.length > 0
        ) != null
      ) {
        return true
      }
      return false
    }
    return false
  }, [recipe])

  const missingFields = useMemo(() => {
    if (recipe != null) {
      const {
        img_url,
        description,
        cook_duration,
        prep_duration,
        ustensils,
        misc,
        steps,
        good_start,
        ingredients
      } = recipe!.recipesFiltered[0]

      const missingFields = []
      if (!img_url) {
        missingFields.push('image URL')
      }
      if (!description) {
        missingFields.push('description')
      }
      if (!cook_duration && !prep_duration) {
        missingFields.push('durée')
      }
      if (!ustensils || ustensils.length === 0) {
        missingFields.push('ustensiles')
      }
      if (!misc || misc.length === 0) {
        missingFields.push('dans le placard')
      }
      if (!steps || steps.length === 0) {
        missingFields.push('étapes')
      }
      if (!good_start) {
        missingFields.push('bien démarrer')
      }
      if (
        ingredients.map(ing => ing.quantity.box_four).filter(qty => qty == null)
          .length > 0
      ) {
        missingFields.push('quantité(s) box 4P')
      }
      if (
        ingredients.map(ing => ing.quantity.box_six).filter(qty => qty == null)
          .length > 0
      ) {
        missingFields.push('quantité(s) box 6P')
      }
      return missingFields
    }
    return []
  }, [recipe])

  return (
    <>
      <Container>
        <div className="flex space-x-4">
          <Button onClick={() => handleUpdate()}>Modifier la recette</Button>
          <Button onClick={() => setCardVisibility(true)}>Voir la fiche</Button>
        </div>
        {queryRecipeLoading && (
          <div className="flex justify-center items-center h-full">
            <ClipLoader size={32} color="#E77F67" />
          </div>
        )}
        {recipe != null && (
          <div className="space-y-4">
            {missingFields.length > 0 && (
              <Alert
                type="warning"
                title="Recette incomplète"
                message={
                  <ul>
                    {missingFields.map((field, i) => (
                      <li key={i}>{field}</li>
                    ))}
                  </ul>
                }
              />
            )}
            {!stepsIllustrated && (
              <Alert type="warning" title="Aucune image de préparation" />
            )}
          </div>
        )}
      </Container>
      {recipe != null && (
        <RecipeCard
          recipe={recipe}
          visible={cardVisible}
          onClose={() => setCardVisibility(false)}
        />
      )}
    </>
  )
}

export default RecipeDetail
