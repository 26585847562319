import React from 'react'
import Dialog from 'rc-dialog'
import IDialogPropTypes from 'rc-dialog/lib/IDialogPropTypes'
import { IoMdClose } from 'react-icons/io'

interface Props extends IDialogPropTypes {}

const Modal: React.FC<Props> = ({ title, children, ...rest }) => {
  return (
    <Dialog
      animation="zoom"
      maskAnimation="fade"
      width={640}
      maskClosable={false}
      destroyOnClose={true}
      focusTriggerAfterClose={false}
      closeIcon={<IoMdClose className="mt-1" size={24} />}
      {...rest}
    >
      <h1 className="mb-6 font-title text-gray-900">{title}</h1>
      {children}
    </Dialog>
  )
}

export default Modal
