import React from 'react'
import IDialogPropTypes from 'rc-dialog/lib/IDialogPropTypes'
import Modal from './Modal'
import { Button } from './Button'

interface Props extends IDialogPropTypes {
  bodyText: string
  submitting?: boolean
  onDismiss: () => void
  onConfirm: () => void
}

const WarningModal: React.FC<Props> = ({
  bodyText,
  submitting = false,
  onDismiss,
  onConfirm,
  ...rest
}) => {
  return (
    <Modal width={560} closable={false} {...rest}>
      <div className="space-y-6">
        {bodyText && (
          <p className="text-sm font-body text-gray-900">{bodyText}</p>
        )}
        <div className="space-x-2 flex justify-end">
          <Button disabled={submitting} onClick={() => onDismiss()}>
            Annuler
          </Button>
          <Button loading={submitting} primary onClick={() => onConfirm()}>
            Confirmer
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default WarningModal
