import React, { useEffect, useState, useMemo } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import _, { capitalize } from 'lodash'
import {
  useGetRecipesQuery,
  useGetRecipesShortInfoLazyQuery,
  RecipeIngredientUnit,
  Diet,
  Allergene,
  RecipeType,
  RecipeCountry,
  QtyPerBoxSize
} from '../types/graphql'
import { displayNotification } from './Notification'
import {
  allergeneToString,
  dietToString,
  getAllergeneColor,
  getDietColor
} from '../utils/ingredients'
import {
  formatIngredientUnit,
  formatRecipeType,
  formatRecipeCountry
} from '../utils/recipes'
import { getUsers, User } from '../api/users'
import { CustomSelect } from './shared/Select'
import Tag from './shared/Tag'
import Table from './shared/Table'
import Container from './shared/Container'
import Alert from './shared/Alert'

// TODO: Box simulation features
// Add boxset on top of the page that show current user coverage
// In the user uncovered section, show which recipes are the blocking point
// When not 100% covered, suggest a new box that will cover the remaining users that will have at least 2 dish + 2 starter/desert
// Show alert if user cannot be 100% covered based on the recipes stored in DB

interface Props {}

const BoxSimulator: React.FC<Props> = () => {
  const {
    loading: queryRecipesLoading,
    error: queryRecipesError,
    data: queryRecipesData
  } = useGetRecipesQuery()
  const [
    getRecipesInfo,
    { data: queryRecipesInfoData }
  ] = useGetRecipesShortInfoLazyQuery()

  const [boxIngredients, setBoxIngredients] = useState<
    {
      id: string
      name: string
      qty: QtyPerBoxSize
      unit: RecipeIngredientUnit
    }[]
  >([])
  const [boxAllergenes, setBoxAllergenes] = useState<Allergene[]>([])
  const [boxDietConstraints, setBoxDietConstraints] = useState<Diet[]>([])

  useEffect(() => {
    if (queryRecipesError) {
      displayNotification(
        'error',
        'Could not fetch recipes.',
        queryRecipesError.message || 'Unknown error'
      )
    }
  }, [queryRecipesError])

  useEffect(() => {
    if (queryRecipesInfoData != null) {
      const allIngredientsQty = _.chain(queryRecipesInfoData.recipesFiltered)
        .map(recipe => recipe.ingredients)
        .flatten()
        .value()
      let unifiedIngredients = [] as {
        id: string
        name: string
        qty: QtyPerBoxSize
        unit: RecipeIngredientUnit
      }[]
      allIngredientsQty.forEach(ing => {
        const found = unifiedIngredients.find(
          _ing => _ing.id === ing.ingredient.id && _ing.unit === ing.unit
        )
        if (found == null) {
          unifiedIngredients.push({
            id: ing.ingredient.id,
            name: ing.ingredient.name,
            qty: ing.quantity,
            unit: ing.unit
          })
        } else {
          const updated = {
            ...found,
            qty: _.mapValues(
              found.qty,
              (val, key) => _.get(ing.quantity, key, 0) + val
            )
          }
          unifiedIngredients = unifiedIngredients.map(ing => {
            if (ing.id === updated.id) {
              return updated
            }
            return ing
          })
        }
      })

      const allergenes = _.chain(queryRecipesInfoData.recipesFiltered)
        .map(recipe => recipe.ingredients.map(ing => ing.ingredient.allergenes))
        .flattenDeep()
        .filter(all => all != null)
        .uniq()
        .value() as Allergene[]

      const dietConstraints = _.chain(queryRecipesInfoData.recipesFiltered)
        .map(recipe =>
          recipe.ingredients.map(ing => ing.ingredient.diet_constraints)
        )
        .flattenDeep()
        .filter(diet => diet != null)
        .uniq()
        .value() as Diet[]

      setBoxIngredients(unifiedIngredients)
      setBoxAllergenes(allergenes)
      setBoxDietConstraints(dietConstraints)
    }
  }, [queryRecipesInfoData]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (selection: any) => {
    const selectedIds =
      selection?.map((sel: { label: string; value: string }) => sel.value) || []
    getRecipesInfo({ variables: { filter: { ids: selectedIds } } })
  }

  const uncoveredUsersData = useMemo(() => {
    const users = getUsers()
    const usersAllergenes = boxAllergenes
      .map(allergene => {
        const uncovered = users.filter(user =>
          user.allergenes.includes(allergene)
        )
        return {
          type: 'allergene',
          constraint: allergene,
          users: uncovered
        }
      })
      .filter(allergene => allergene.users.length !== 0)
    const usersDiet = boxDietConstraints
      .map(diet => {
        const uncovered = users.filter(user =>
          user.diet_constraints.includes(diet)
        )
        return {
          type: 'diet',
          constraint: diet,
          users: uncovered
        }
      })
      .filter(diet => diet.users.length !== 0)

    const merged = [...usersAllergenes, ...usersDiet]
    return {
      users: _.chain(merged)
        .map(data => data.users)
        .flatten()
        .uniqBy('id')
        .value(),
      data: merged
    }
  }, [boxAllergenes, boxDietConstraints])

  const renderBoxAllergenes = () => {
    if (boxAllergenes.length > 0) {
      return (
        <div className="flex flex-wrap">
          {boxAllergenes.map(allergene => (
            <div key={allergene} className="m-1">
              <Tag color={getAllergeneColor(allergene)}>
                {allergeneToString(allergene)}
              </Tag>
            </div>
          ))}
        </div>
      )
    }
    return null
  }

  const renderBoxDietConstraints = () => {
    if (boxDietConstraints.length > 0) {
      return (
        <div className="flex flex-wrap">
          {boxDietConstraints.map(diet => (
            <div key={diet} className="m-1">
              <Tag color={getDietColor(diet)}>{dietToString(diet)}</Tag>
            </div>
          ))}
        </div>
      )
    }
    return null
  }

  const recipesCols = [
    {
      Header: 'Nom',
      accessor: 'name',
      Cell: ({ value }: { value: string }) => capitalize(value)
    },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: ({ value }: { value: RecipeType }) => formatRecipeType(value)
    },
    {
      Header: 'Pays',
      accessor: 'country',
      Cell: ({ value }: { value: RecipeCountry }) => formatRecipeCountry(value)
    }
  ]

  const ingredientListCols = [
    {
      Header: 'Nom',
      accessor: 'name',
      Cell: ({ value }: { value: string }) => capitalize(value)
    },
    {
      Header: 'Qté 2P',
      accessor: (row: {
        id: string
        name: string
        qty: QtyPerBoxSize
        unit: RecipeIngredientUnit
      }) => row,
      disableSortBy: true,
      Cell: ({
        value
      }: {
        value: {
          id: string
          name: string
          qty: QtyPerBoxSize
          unit: RecipeIngredientUnit
        }
      }) => `${value.qty.box_two} ${formatIngredientUnit(value.unit)}`
    },
    {
      Header: 'Qté 4P',
      accessor: (row: {
        id: string
        name: string
        qty: QtyPerBoxSize
        unit: RecipeIngredientUnit
      }) => row,
      disableSortBy: true,
      Cell: ({
        value
      }: {
        value: {
          id: string
          name: string
          qty: QtyPerBoxSize
          unit: RecipeIngredientUnit
        }
      }) =>
        value.qty.box_four
          ? `${value.qty.box_four} ${formatIngredientUnit(value.unit)}`
          : ''
    },
    {
      Header: 'Qté 6P',
      accessor: (row: {
        id: string
        name: string
        qty: QtyPerBoxSize
        unit: RecipeIngredientUnit
      }) => row,
      disableSortBy: true,
      Cell: ({
        value
      }: {
        value: {
          id: string
          name: string
          qty: QtyPerBoxSize
          unit: RecipeIngredientUnit
        }
      }) =>
        value.qty.box_six
          ? `${value.qty.box_six} ${formatIngredientUnit(value.unit)}`
          : ''
    }
  ]

  const uncoveredUserCols = [
    {
      Header: 'Contrainte',
      accessor: (row: {
        type: string
        count: number
        constraint: Allergene | Diet
      }) => row,
      disableSortBy: true,
      Cell: ({
        value
      }: {
        value: {
          type: string
          count: number
          constraint: Allergene | Diet
        }
      }) => {
        if (value.type === 'allergene') {
          return (
            <div className="flex">
              <Tag color={getAllergeneColor(value.constraint as Allergene)}>
                {allergeneToString(value.constraint as Allergene)}
              </Tag>
            </div>
          )
        } else {
          return (
            <div className="flex">
              <Tag color={getDietColor(value.constraint as Diet)}>
                {dietToString(value.constraint as Diet)}
              </Tag>
            </div>
          )
        }
      }
    },
    {
      Header: 'Nb',
      accessor: 'users',
      Cell: ({ value }: { value: User[] }) => value.length
    }
  ]

  if (queryRecipesLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <ClipLoader size={32} color="#E77F67" />
      </div>
    )
  }
  return (
    <div className="space-y-6">
      <CustomSelect
        isSearchable
        multiselect
        placeholder="Selectionner les recettes"
        items={
          queryRecipesData?.recipes.edges
            .map(edge => edge.node)
            .map(rec => ({
              value: rec.id,
              label: capitalize(rec.name)
            })) || []
        }
        onChange={handleChange}
      />
      {queryRecipesInfoData && queryRecipesInfoData.recipesFiltered.length > 0 && (
        <div className="flex space-x-6">
          <div className="space-y-6 w-1/2 max-w-md">
            <Container>
              <div className="space-y-4">
                <h2 className="text-gray-900 font-title">Overview</h2>
                <Table
                  columns={recipesCols}
                  data={queryRecipesInfoData.recipesFiltered}
                />
              </div>
              <div className="space-y-4">
                <h2 className="text-sm text-gray-900 font-title">Allergènes</h2>
                <div>{renderBoxAllergenes()}</div>
              </div>
              <div className="space-y-4">
                <h2 className="text-sm text-gray-900 font-title">
                  Régimes incompatibles
                </h2>
                <div>{renderBoxDietConstraints()}</div>
              </div>
            </Container>
            {uncoveredUsersData.users.length === 0 ? (
              <Alert
                type="success"
                title="Niceuhh"
                message="Tous les utilisateurs peuvent recevoir cette box !"
              />
            ) : (
              <Container>
                <div className="space-y-4">
                  <h2 className="text-gray-900 font-title">
                    {uncoveredUsersData.users.length} utilisateurs incompatibles
                  </h2>
                  <Table
                    columns={uncoveredUserCols}
                    data={uncoveredUsersData.data}
                  />
                </div>
              </Container>
            )}
          </div>
          <div className="flex-auto">
            <Container>
              <div className="space-y-4">
                <h2 className="text-gray-900 font-title">
                  Liste des ingrédients
                </h2>
                <Table columns={ingredientListCols} data={boxIngredients} />
              </div>
            </Container>
          </div>
        </div>
      )}
    </div>
  )
}

export default BoxSimulator
