import React, { useMemo } from 'react'
import { GetRecipesFullInfoQuery, IngredientQty } from '../../../types/graphql'
import { ReactComponent as CookingIcon } from '../../../assets/svg/cooking.svg'
import { ReactComponent as UstensilsIcon } from '../../../assets/svg/ustensils.svg'
import { ReactComponent as ClosetIcon } from '../../../assets/svg/closet.svg'
import { ReactComponent as GoodStartIcon } from '../../../assets/svg/good-start.svg'
import { ReactComponent as IngredientsIcon } from '../../../assets/svg/ingredients.svg'
import styles from './Recto.module.css'
import { formatRecipeCountry, formatRecipeType } from '../../../utils/recipes'
import { formatDuration } from '../../../utils'
import BlockSubtitle from './BlockSubtitle'
import IngredientOverview from './IngredientsOverview'
import BulletList from './BulletList'

// TODO: Show servings info

interface Props {
  recipe: GetRecipesFullInfoQuery
}

const Recto: React.FC<Props> = ({ recipe }) => {
  const recipeData = useMemo(() => recipe.recipesFiltered[0], [recipe])

  return (
    <div className="flex absolute w-full h-full left-0 top-0">
      <div className={styles.illustrationWrapper}>
        {recipeData.img_url && (
          <img
            loading="lazy"
            className={styles.illustration}
            crossOrigin="anonymous"
            src={recipeData.img_url}
            alt=""
          />
        )}
        <div className={styles.countryWrapper}>
          <p className={styles.countryTitle}>
            {formatRecipeCountry(recipeData.country)}
          </p>
        </div>
        <div className={styles.typeWrapper}>
          <p className={styles.typeTitle}>
            {formatRecipeType(recipeData.type)}
          </p>
        </div>
      </div>
      <div className={styles.generalWrapper}>
        <div className={styles.block}>
          <p className={styles.recipeName}>
            {recipeData.name}{' '}
            {recipeData.original_name && (
              <p className={styles.originalName}>{recipeData.original_name}</p>
            )}
          </p>

          <div className="flex items-center">
            {recipeData.prep_duration && (
              <div className={styles.tag}>
                <BlockSubtitle
                  noMargin
                  label={formatDuration(recipeData.prep_duration)}
                  Icon={GoodStartIcon}
                />
              </div>
            )}
            {recipeData.cook_duration && (
              <div className={styles.tag}>
                <BlockSubtitle
                  noMargin
                  label={formatDuration(recipeData.cook_duration)}
                  Icon={CookingIcon}
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles.block}>
          {recipeData.description?.split('\n').map((str, i) => (
            <p className={styles.description} key={i}>
              {str}
            </p>
          ))}
        </div>
        <div className={styles.block}>
          {/* <div className="flex flex-row">
            <BlockSubtitle label="Ingrédients" Icon={IngredientsIcon} />
            {recipeData.servings_info && (
              <span className={styles.ingredientsExtraInfo}>
                {recipeData.servings_info}
              </span>
            )}
            <span className={styles.ingredientsExtraInfo}>2 Personnes</span>
          </div> */}
          <IngredientOverview
            items={recipeData.ingredients as IngredientQty[]}
          />
        </div>
        <div className={styles.block}>
          <div className="flex">
            {recipeData.ustensils && (
              <div className="w-1/2">
                <BlockSubtitle label="Ustensiles" Icon={UstensilsIcon} />
                <BulletList items={recipeData.ustensils!} />
              </div>
            )}
            {recipeData.misc && (
              <div className="w-1/2">
                <BlockSubtitle label="Dans le placard" Icon={ClosetIcon} />
                <BulletList items={recipeData.misc!} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Recto
