import React from 'react'
import styles from './DataList.module.css'

interface Props {
  header: string[]
  rows: string[][]
  colSize: number[]
}

const DataList: React.FC<Props> = ({ header, rows, colSize }) => {
  return (
    <div>
      <div className={styles.tableRowTransparent}>
        {header.map((h, i) => {
          return (
            <div
              key={i}
              className={i !== 0 ? styles.tableCell : styles.firstColTableCell}
              style={{ flex: colSize[i] }}
            >
              <span className={styles.tableHeader}>{h}</span>
            </div>
          )
        })}
      </div>
      {rows.map((row, i) => {
        return (
          <div
            className={
              i % 2 === 0 ? styles.tableRow : styles.tableRowTransparent
            }
            key={i}
          >
            {row.map((cell, j) => (
              <div
                className={
                  j !== 0 ? styles.tableCell : styles.firstColTableCell
                }
                key={j}
                style={{ flex: colSize[j] }}
              >
                <span className={styles.cellText}>{cell}</span>
              </div>
            ))}
          </div>
        )
      })}
    </div>
  )
}

export default DataList
