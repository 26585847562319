import { Allergene, Diet } from '../types/graphql'

export const dietToString = (diet: Diet) => {
  switch (diet) {
    case Diet.Vegan:
      return 'Végan'
    case Diet.Vegetarian:
      return 'Végétarien'
    case Diet.Pescaterian:
      return 'Pescatérien'
    default:
      return ''
  }
}

export const getDietColor = (diet: Diet) => {
  switch (diet) {
    case Diet.Vegan:
      return '#badc58'
    case Diet.Vegetarian:
      return '#6ab04c'
    case Diet.Pescaterian:
      return '#ff7979'
    default:
      return 'gray'
  }
}

export const allergeneToString = (allergene: Allergene) => {
  switch (allergene) {
    case Allergene.Gluten:
      return 'Gluten'
    case Allergene.Crustaceans:
      return 'Crustacés'
    case Allergene.Eggs:
      return 'Oeufs'
    case Allergene.Fish:
      return 'Poissons'
    case Allergene.Peanuts:
      return 'Arachides'
    case Allergene.Soybeans:
      return 'Soja'
    case Allergene.Milk:
      return 'Lait'
    case Allergene.Nuts:
      return 'Fruits à coques'
    case Allergene.Celery:
      return 'Céleri'
    case Allergene.Mustard:
      return 'Moutarde'
    case Allergene.SesameSeeds:
      return 'Graines de sésames'
    case Allergene.Sulphur:
      return 'Anhydride sulfureux'
    case Allergene.Lupin:
      return 'Lupin'
    case Allergene.Molluscs:
      return 'Mollusques'
    default:
      return ''
  }
}

export const getAllergeneColor = (allergene: Allergene) => {
  switch (allergene) {
    case Allergene.Gluten:
      return '#ff4757'
    case Allergene.Crustaceans:
      return '#f8a5c2'
    case Allergene.Eggs:
      return '#ffeaa7'
    case Allergene.Fish:
      return '#63cdda'
    case Allergene.Peanuts:
      return '#f19066'
    case Allergene.Soybeans:
      return 'gold'
    case Allergene.Milk:
      return '#8395a7'
    case Allergene.Nuts:
      return '#cc8e35'
    case Allergene.Celery:
      return '#badc58'
    case Allergene.Mustard:
      return '#fbc531'
    case Allergene.SesameSeeds:
      return '#2c3e50'
    case Allergene.Sulphur:
      return '#1abc9c'
    case Allergene.Lupin:
      return '#5758BB'
    case Allergene.Molluscs:
      return '#1289A7'
    default:
      return 'gray'
  }
}
