import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { Control, Controller } from 'react-hook-form'

interface Props extends React.HTMLProps<HTMLTextAreaElement> {
  control: Control
  rules?: {
    [key: string]: any
  }
}

const TextArea: React.FC<Props> = ({ control, rules, name, placeholder }) => {
  return (
    <Controller
      name={name!}
      control={control}
      rules={rules}
      render={({ onChange, onBlur, value }) => (
        <TextareaAutosize
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          className="rounded w-full px-4 py-2 font-body text-xs text-gray-900 outline-none border border-gray-300 focus:border-orange-500 transition-all duration-300"
        />
      )}
    />
  )
}

export default TextArea
