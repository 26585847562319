import React, { useEffect, useState, useMemo } from 'react'
import _ from 'lodash'
import {
  formatRecipeType,
  formatRecipeCountry,
  formatIngredientUnit
} from '../../utils/recipes'
import {
  RecipeType,
  RecipeCountry,
  RecipeIngredientUnit,
  CreateRecipe,
  UpdateRecipe,
  useGetIngredientsQuery,
  IngredientQtyInput
} from '../../types/graphql'
import { useForm } from 'react-hook-form'
import Modal from '../shared/Modal'
import FormItem from '../shared/FormItem'
import Input from '../shared/Input'
import FormList from '../shared/FormList'
import { Button } from '../shared/Button'
import TextArea from '../shared/TextArea'
import FormGroup from '../shared/FormGroup'
import { ControlledSelect } from '../shared/Select'
import {
  cleanObjectValues,
  requiredRule,
  stringLenRule
} from '../../utils/form'
import InputNumber from '../shared/InputNumber'

interface Props {
  initialValues: UpdateRecipe | null
  visible: boolean
  submitting: boolean
  onCreate: (values: CreateRecipe) => void
  onUpdate: (values: UpdateRecipe) => void
  onCancel: (changed: boolean) => void
}

const RecipeCreation: React.FC<Props> = ({
  initialValues,
  visible,
  submitting,
  onCancel,
  onCreate,
  onUpdate
}) => {
  const { data: queryIngredientsData } = useGetIngredientsQuery()
  const [formInitialValues, setFormInitialValues] = useState<
    Omit<UpdateRecipe, 'id'>
  >({})
  const { register, control, errors, handleSubmit, getValues, reset } = useForm<
    Omit<UpdateRecipe, 'id'>
  >()

  useEffect(() => {
    let values: Omit<UpdateRecipe, 'id'> = {
      ingredients: [{} as IngredientQtyInput]
    }
    if (initialValues && visible) {
      const cleanedValues = cleanObjectValues(initialValues)
      values = {
        ...cleanedValues,
        name: _.capitalize(cleanedValues.name!),
        ingredients: cleanedValues.ingredients || [{}]
      }
    }
    reset(values)
    setFormInitialValues(values)
  }, [initialValues, visible, reset])

  const recipeTypeOptions = useMemo(() => {
    return Object.values(RecipeType).map(type => ({
      value: type,
      label: formatRecipeType(type)
    }))
  }, [])

  const recipeCountryOptions = useMemo(() => {
    return Object.values(RecipeCountry).map(country => ({
      value: country,
      label: formatRecipeCountry(country)
    }))
  }, [])

  const ingredientUnitOptions = useMemo(() => {
    return Object.values(RecipeIngredientUnit).map(unit => ({
      value: unit,
      label: formatIngredientUnit(unit)
    }))
  }, [])

  const ingredientOptions = useMemo(() => {
    return (
      queryIngredientsData?.ingredients.edges.map(ingr => ({
        label: _.capitalize(ingr.node.name),
        value: ingr.node.id
      })) || []
    )
  }, [queryIngredientsData])

  const onSubmit = handleSubmit(async values => {
    const cleaned = cleanObjectValues(values)
    try {
      if (initialValues != null) {
        onUpdate({ id: initialValues.id, ...cleaned })
      } else {
        onCreate(cleaned as CreateRecipe)
      }
    } catch (error) {}
  })

  const handleCancel = () => {
    if (!submitting) {
      onCancel(
        !_.isEqualWith(
          cleanObjectValues(formInitialValues, true),
          cleanObjectValues(getValues(), true),
          (obj: any, oth: any) => {
            if (_.isArray(obj) && _.isArray(oth)) {
              return _.isEqual(_.sortBy(obj), _.sortBy(oth))
            }
            return undefined
          }
        )
      )
    }
  }

  return (
    <Modal
      width={800}
      visible={visible}
      title={initialValues != null ? 'Modifier la recette' : 'Nouvelle recette'}
      onClose={() => handleCancel()}
    >
      <form className="space-y-4" onSubmit={onSubmit}>
        <div className="flex space-x-4">
          <div className="flex-auto">
            <FormItem required label="Nom de la recette" error={errors.name}>
              <Input
                register={register({
                  ...requiredRule(),
                  ...stringLenRule(3, 100)
                })}
                name="name"
                placeholder="Ex: Kimbap au thon"
              />
            </FormItem>
          </div>
          <div className="flex-auto">
            <FormItem label="Nom original">
              <Input
                register={register}
                name="original_name"
                placeholder="Ex: 참치김밥"
              />
            </FormItem>
          </div>
        </div>
        <FormItem label="Lien vers l'image">
          <Input
            register={register}
            name="img_url"
            placeholder="Ex: https://mukbox-recipes.s3.eu-west-3.amazonaws.com/recipes/kimbap-thon/hero.jpg"
          />
        </FormItem>
        <div className="flex space-x-4">
          <div className="w-1/4">
            <FormItem label="Type" required error={errors.type}>
              <ControlledSelect
                name="type"
                control={control}
                rules={requiredRule()}
                items={recipeTypeOptions}
                placeholder="Entrée, plat, ..."
              />
            </FormItem>
          </div>
          <div className="w-1/4">
            <FormItem label="Pays d'origine" required error={errors.country}>
              <ControlledSelect
                name="country"
                control={control}
                rules={requiredRule()}
                items={recipeCountryOptions}
                placeholder="Corée du sud ..."
                isSearchable
              />
            </FormItem>
          </div>
          <div className="w-1/4">
            <FormItem label="Temps de préparation">
              <InputNumber
                name="prep_duration"
                placeholder="En minutes"
                control={control}
                step={1}
              />
            </FormItem>
          </div>
          <div className="w-1/4">
            <FormItem label="Temps de cuisson">
              <InputNumber
                name="cook_duration"
                placeholder="En minutes"
                control={control}
                step={1}
              />
            </FormItem>
          </div>
        </div>
        <FormItem label="Informations sur les portions">
          <Input
            register={register}
            name="servings_info"
            placeholder="Ex: 2 Rouleaux par personne"
          />
        </FormItem>
        <FormItem label="Description">
          <TextArea name="description" control={control} />
        </FormItem>
        <FormGroup name="Ingredients">
          <FormList
            isSortable
            control={control}
            name="ingredients"
            addLabel="Nouvel ingrédient"
            minItems={1}
            renderField={(field: any, index) => (
              <div className="flex space-x-1">
                <div className="flex-auto">
                  <FormItem
                    required
                    error={
                      (errors.ingredients as any | undefined)?.[index]
                        ?.ingredient_id
                    }
                  >
                    <ControlledSelect
                      name={`ingredients[${index}].ingredient_id`}
                      control={control}
                      rules={requiredRule()}
                      items={ingredientOptions}
                      isSearchable
                      placeholder="Kimchi, Riz, ..."
                    />
                  </FormItem>
                </div>
                <FormItem
                  required
                  error={
                    (errors.ingredients as any | undefined)?.[index]?.quantity
                      .box_two
                  }
                >
                  <InputNumber
                    name={`ingredients[${index}].quantity.box_two`}
                    control={control}
                    placeholder="Qté 2P"
                    containerWidth="w-24"
                    rules={requiredRule()}
                  />
                </FormItem>
                <FormItem>
                  <InputNumber
                    name={`ingredients[${index}].quantity.box_four`}
                    control={control}
                    placeholder="Qté 4P"
                    containerWidth="w-24"
                  />
                </FormItem>
                <FormItem>
                  <InputNumber
                    name={`ingredients[${index}].quantity.box_six`}
                    control={control}
                    placeholder="Qté 6P"
                    containerWidth="w-24"
                  />
                </FormItem>
                <FormItem
                  required
                  error={(errors.ingredients as any | undefined)?.[index]?.unit}
                >
                  <ControlledSelect
                    name={`ingredients[${index}].unit`}
                    control={control}
                    rules={requiredRule()}
                    items={ingredientUnitOptions}
                    isSearchable
                    placeholder="Unité"
                  />
                </FormItem>
              </div>
            )}
          />
        </FormGroup>
        <div className="flex space-x-8">
          <div className="w-2/4">
            <FormGroup name="Ustensiles">
              <FormList
                control={control}
                name="ustensils"
                addLabel="Ajouter un ustensile"
                renderField={(field: any, index) => (
                  <FormItem
                    error={(errors.ustensils as any | undefined)?.[index]}
                  >
                    <Input
                      name={`ustensils[${index}]`}
                      register={register(requiredRule())}
                      placeholder="Ex: Casserole"
                      defaultValue={field.value}
                    />
                  </FormItem>
                )}
              />
            </FormGroup>
          </div>
          <div className="w-2/4">
            <FormGroup name="Dans le placard">
              <FormList
                control={control}
                name="misc"
                addLabel="Ajouter quelque chose"
                renderField={(field: any, index) => (
                  <FormItem error={(errors.misc as any | undefined)?.[index]}>
                    <Input
                      name={`misc[${index}]`}
                      register={register(requiredRule())}
                      placeholder="Ex: Sel"
                      defaultValue={field.value}
                    />
                  </FormItem>
                )}
              />
            </FormGroup>
          </div>
        </div>
        <FormGroup name="Bien commencer">
          <FormItem>
            <TextArea name="good_start" control={control} />
          </FormItem>
        </FormGroup>
        <FormGroup name="Préparation">
          <FormList
            isSortable
            control={control}
            name="steps"
            addLabel="Nouvelle étape"
            renderField={(field: any, index) => (
              <div className="space-y-1 mb-4">
                <FormItem
                  error={(errors.steps as any | undefined)?.[index]?.name}
                >
                  <Input
                    name={`steps[${index}].name`}
                    placeholder="Nom de l'étape"
                    defaultValue={field.name}
                    register={register(requiredRule())}
                  />
                </FormItem>
                <FormItem
                  error={
                    (errors.steps as any | undefined)?.[index]?.description
                  }
                >
                  <TextArea
                    name={`steps[${index}].description`}
                    placeholder="Description"
                    defaultValue={field.description}
                    rules={requiredRule()}
                    control={control}
                  />
                </FormItem>
                <FormList
                  control={control}
                  name={`steps[${index}].img`}
                  addLabel="Ajouter une image à l'étape"
                  renderField={(field: any, subIndex) => (
                    <FormItem
                      error={
                        (errors.steps as any | undefined)?.[index]?.img?.[
                          subIndex
                        ]
                      }
                    >
                      <Input
                        name={`steps[${index}].img[${subIndex}]`}
                        defaultValue={field.value}
                        register={register(requiredRule())}
                        placeholder="Ex: https://mukbox-recipes.s3.eu-west-3.amazonaws.com/recipes/kimchi-thon/bla.png"
                      />
                    </FormItem>
                  )}
                />
              </div>
            )}
          />
        </FormGroup>
        <div className="space-x-2 flex justify-end">
          <Button onClick={() => handleCancel()}>Annuler</Button>
          <Button submit loading={submitting}>
            Enregistrer
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default RecipeCreation
