import React, { useState } from 'react'
import { Switch, Route, useLocation, Link, matchPath } from 'react-router-dom'
import Sidebar from './Sidebar'
import Dashboard from './Dashboard'
import Users from './Users'
import Recipes from './recipes/Recipes'
import Ingredients from './ingredients/Ingredients'
import BoxSimulator from './BoxSimulator'

interface Props {}

const breadcrumbs = [
  '/',
  '/users',
  '/ingredients',
  '/recipes',
  '/recipes/:id',
  '/boxsimulator'
]

const Main: React.FC<Props> = () => {
  const [currentRecipeName, setCurrentRecipeName] = useState('')
  const { pathname } = useLocation()

  const getCrumbName = (url: string) => {
    const path = breadcrumbs.find(path => {
      return (
        matchPath(url, {
          path: path,
          exact: true,
          strict: true
        }) != null
      )
    })
    switch (path) {
      case '/':
        return 'Dashboard'
      case '/users':
        return 'Users'
      case '/ingredients':
        return 'Ingredients'
      case '/recipes':
        return 'Recettes'
      case '/recipes/:id':
        return currentRecipeName
      case '/boxsimulator':
        return 'Simulation de box'
      default:
        return ''
    }
  }

  const renderBreadcrumbs = () => {
    const snippets = pathname.split('/').filter(i => i)
    const crumbsItems = snippets.map((_, index) => {
      const url = `/${snippets.slice(0, index + 1).join('/')}`
      const last = index === snippets.length - 1
      return (
        <div
          key={url}
          className={`font-title text-xl ${
            last ? 'text-gray-900' : 'text-gray-500'
          }`}
        >
          <Link to={url}>{getCrumbName(url)}</Link>
          {!last && <span className="ml-4">/</span>}
        </div>
      )
    })

    if (snippets.length === 0) {
      return (
        <div key="/" className="font-title text-xl text-gray-900">
          <Link to="/">{getCrumbName('/')}</Link>
        </div>
      )
    }
    return <div className="flex space-x-4 items-baseline">{crumbsItems}</div>
  }

  return (
    <div className="bg-gray-100 min-h-screen">
      <Sidebar />
      <div className="ml-56 px-8 py-6 space-y-4">
        {renderBreadcrumbs()}
        <Switch>
          <Route exact path="/">
            <Dashboard />
          </Route>
          <Route path="/users">
            <Users />
          </Route>
          <Route path="/ingredients">
            <Ingredients />
          </Route>
          <Route path="/recipes">
            <Recipes setBreadcrumbRecipeName={setCurrentRecipeName} />
          </Route>
          <Route path="/boxsimulator">
            <BoxSimulator />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

export default Main
