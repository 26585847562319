import React, { useMemo } from 'react'
import { getUsers } from '../api/users'
import Table from './shared/Table'
import Container from './shared/Container'

interface Props {}

const Users: React.FC<Props> = () => {
  const columns = [
    {
      Header: 'Nom',
      accessor: 'name'
    },
    {
      Header: 'Prénom',
      accessor: 'firstname',
      disableSortBy: true
    },
    {
      Header: 'Abonnement',
      accessor: 'subscription_plan',
      disableSortBy: true,
      Cell: ({ value }: { value: { plan: string } }) => value.plan
    }
  ]

  const users = useMemo(() => {
    return getUsers()
  }, [])

  return (
    <Container>
      <Table columns={columns} data={users} />
    </Container>
  )
}

export default Users
