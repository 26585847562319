import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as Logo } from '../assets/svg/logo-dark.svg'
import { GoDashboard, GoBook } from 'react-icons/go'
import { AiOutlineExperiment } from 'react-icons/ai'
import { FaListUl, FaRegUser } from 'react-icons/fa'
import { IconType } from 'react-icons/lib'

type Item = {
  key: string
  label: string
  route: string
  icon: IconType
}

const menuItems: Item[] = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    route: '',
    icon: GoDashboard
  },
  {
    key: 'users',
    label: 'Users',
    route: 'users',
    icon: FaRegUser
  },
  {
    key: 'ingredients',
    label: 'Ingrédients',
    route: 'ingredients',
    icon: FaListUl
  },
  {
    key: 'recipes',
    label: 'Recettes',
    route: 'recipes',
    icon: GoBook
  },
  {
    key: 'boxsimulation',
    label: 'Box simulation',
    route: 'boxsimulator',
    icon: AiOutlineExperiment
  }
]

const MenuItem: React.FC<{ item: Item; active?: boolean }> = ({
  item,
  active = false
}) => {
  return (
    <div
      className={`rounded-md px-4 py-3 ${
        active ? 'text-white' : 'text-gray-700'
      } ${active && 'bg-orange-500'}`}
    >
      <Link to={`/${item.route}`}>
        <div className="flex items-center font-title text-sm">
          <item.icon size={16} color={active ? 'white' : '#4a6855'} />
          <span className="ml-4 text-xs">{item.label}</span>
        </div>
      </Link>
    </div>
  )
}

const Sidebar: React.FC<{}> = () => {
  const location = useLocation()

  const isMenuItemActive = (key: string) => {
    const urlPath = location.pathname.split('/')[1]
    const item = menuItems.find(item => urlPath === item.route)
    return item && item.key === key
  }

  return (
    <div className="fixed h-screen bg-white w-56 shadow-sm">
      <div className="flex justify-center py-8">
        <Logo width={116} height={18} />
      </div>
      <div className="px-4 space-y-2">
        {menuItems.map(item => (
          <MenuItem
            key={item.key}
            item={item}
            active={isMenuItemActive(item.key)}
          />
        ))}
      </div>
    </div>
  )
}

export default Sidebar
