import { gql } from '@apollo/client'

export const GET_RECIPES = gql`
  query GetRecipes($first: Int, $after: DateTime) {
    recipes(first: $first, after: $after) {
      edges {
        node {
          id
          name
          original_name
          type
          country
          cook_duration
          prep_duration
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`

export const GET_RECIPES_SHORT_INFO = gql`
  query GetRecipesShortInfo($filter: RecipeFilter!) {
    recipesFiltered(filter: $filter) {
      id
      name
      type
      country
      cook_duration
      prep_duration
      ingredients {
        ingredient {
          id
          name
          allergenes
          diet_constraints
        }
        quantity {
          box_two
          box_four
          box_six
        }
        unit
      }
    }
  }
`

export const GET_RECIPES_FULL_INFO = gql`
  query GetRecipesFullInfo($filter: RecipeFilter!) {
    recipesFiltered(filter: $filter) {
      id
      name
      original_name
      img_url
      description
      servings_info
      type
      country
      cook_duration
      prep_duration
      ingredients {
        ingredient {
          id
          name
          img_url
          allergenes
          diet_constraints
        }
        quantity {
          box_two
          box_four
          box_six
        }
        unit
      }
      ustensils
      misc
      good_start
      steps {
        name
        description
        img
      }
    }
  }
`

export const CREATE_RECIPE = gql`
  mutation CreateRecipe($create: CreateRecipe!) {
    createRecipe(create: $create) {
      id
      name
      original_name
      img_url
      description
      servings_info
      type
      country
      cook_duration
      prep_duration
      ingredients {
        ingredient {
          name
        }
        quantity {
          box_two
          box_four
          box_six
        }
        unit
      }
      ustensils
      misc
      good_start
      steps {
        name
        description
        img
      }
      created_at
    }
  }
`

export const UPDATE_RECIPE = gql`
  mutation UpdateRecipe($update: UpdateRecipe!) {
    updateRecipe(update: $update) {
      id
      name
      original_name
      img_url
      description
      servings_info
      type
      country
      cook_duration
      prep_duration
      ingredients {
        ingredient {
          name
        }
        quantity {
          box_two
          box_four
          box_six
        }
        unit
      }
      ustensils
      misc
      good_start
      steps {
        name
        description
        img
      }
    }
  }
`

export const DELETE_RECIPE = gql`
  mutation DeleteRecipe($id: ID!) {
    deleteRecipe(id: $id)
  }
`
