import {
  RecipeType,
  RecipeCountry,
  RecipeIngredientUnit,
  GetRecipesFullInfoQuery
} from '../types/graphql'

export const getRecipeTypeColor = (type: RecipeType) => {
  switch (type) {
    case RecipeType.Starter:
      return '#48BB78'
    case RecipeType.Dish:
      return '#F56565'
    case RecipeType.Dessert:
      return '#81E6D9'
    default:
      return ''
  }
}

export const formatRecipeType = (type: RecipeType) => {
  switch (type) {
    case RecipeType.Starter:
      return 'Entrée'
    case RecipeType.Dish:
      return 'Plat principal'
    case RecipeType.Dessert:
      return 'Dessert'
    default:
      return ''
  }
}

export const formatRecipeCountry = (country: RecipeCountry) => {
  switch (country) {
    case RecipeCountry.Cambodia:
      return 'Cambodge'
    case RecipeCountry.China:
      return 'Chine'
    case RecipeCountry.Japan:
      return 'Japon'
    case RecipeCountry.SouthKorea:
      return 'Corée du sud'
    case RecipeCountry.Thailand:
      return 'Thailande'
    case RecipeCountry.Vietnam:
      return 'Vietnam'
    case RecipeCountry.Laos:
      return 'Laos'
    case RecipeCountry.India:
      return 'Inde'
    case RecipeCountry.Philippines:
      return 'Philippines'
    default:
      return ''
  }
}

export const formatIngredientUnit = (
  unit: RecipeIngredientUnit,
  full = false,
  plural = false
) => {
  switch (unit) {
    case RecipeIngredientUnit.Piece:
      return full ? ` pièce${plural ? 's' : ''}` : 'pc'
    case RecipeIngredientUnit.Ml:
      return 'mL'
    case RecipeIngredientUnit.Cl:
      return 'cL'
    case RecipeIngredientUnit.L:
      return 'L'
    case RecipeIngredientUnit.G:
      return 'g'
    case RecipeIngredientUnit.Kg:
      return 'kg'
    case RecipeIngredientUnit.Tbsp:
      return full ? ` cuillère${plural ? 's' : ''} à soupe` : 'cas'
    case RecipeIngredientUnit.Tsp:
      return full ? ` cuillère${plural ? 's' : ''} à café` : 'cac'
    case RecipeIngredientUnit.Branch:
      return `branche${plural ? 's' : ''}`
    case RecipeIngredientUnit.Leaf:
      return `feuille${plural ? 's' : ''}`
    default:
      return ''
  }
}

export const recipeToUpdate = (recipeInfo: GetRecipesFullInfoQuery) => {
  const recipeData = recipeInfo.recipesFiltered[0]
  const recipe = {
    ...recipeData,
    ingredients: recipeData.ingredients.map(ing => {
      return {
        ingredient_id: ing.ingredient.id,
        quantity: ing.quantity,
        unit: ing.unit
      }
    })
  }
  return recipe
}
