import React from 'react'
import styles from './BulletList.module.css'

const BulletList: React.FC<{ items: string[] }> = ({ items }) => {
  return (
    <div>
      {items.map(item => (
        <div className="flex" key={item}>
          <div className={styles.dotWrapper}>
            <p className={styles.dot}>●</p>
          </div>
          <p className={styles.itemText}>{item}</p>
        </div>
      ))}
    </div>
  )
}

export default BulletList
