import React from 'react'

interface Props extends React.HTMLProps<HTMLInputElement> {
  containerWidth?: string
  register?: any
  suffix?: React.ReactNode
}

const Input: React.FC<Props> = ({
  containerWidth,
  register,
  suffix,
  ...rest
}) => {
  return (
    <div className={`flex ${containerWidth && containerWidth}`}>
      <input
        ref={register || undefined}
        className={`${
          suffix ? 'rounded-l' : 'rounded'
        } w-full px-4 py-2 font-body text-xs text-gray-900 outline-none border border-gray-300 focus:border-orange-500 transition-all duration-300`}
        {...rest}
      />
      {suffix && (
        <div className="text-gray-900 rounded-r border-t border-b border-r border-gray-300 bg-gray-100 flex justify-center items-center">
          {suffix}
        </div>
      )}
    </div>
  )
}

export default Input
