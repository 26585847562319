import React from 'react'
import Notification from 'rc-notification'
import { NotificationInstance } from 'rc-notification/lib/Notification'
import Alert from './shared/Alert'

let notification: NotificationInstance | null = null

export const initNotificationhandler = () => {
  Notification.newInstance(
    {
      style: { position: 'absolute', top: 32, right: 32 }
    },
    n => (notification = n)
  )
}

export const displayNotification = (
  type: 'success' | 'info' | 'warning' | 'error',
  title: string,
  message: string
) => {
  if (notification != null) {
    notification.notice({
      key: Date.now(),
      content: (
        <div className="max-w-sm shadow-md rounded">
          <Alert title={title} message={message} type={type} />
        </div>
      ),
      closable: false,
      duration: 3,
      style: {
        padding: 0,
        border: 'none',
        boxShadow: 'none'
      }
    })
  }
}
