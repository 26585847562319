import React from 'react'
import { useFieldArray, ArrayField, Control } from 'react-hook-form'
import { Button } from './Button'
import { RiDeleteBin2Line } from 'react-icons/ri'
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti'

interface Props {
  isSortable?: boolean
  control: Control
  name: string
  addLabel: string
  minItems?: number
  renderField: (
    field: Partial<ArrayField<Record<string, any>, 'id'>>,
    index: number
  ) => React.ReactNode
}

const FormList: React.FC<Props> = ({
  isSortable = false,
  control,
  name,
  addLabel,
  minItems,
  renderField
}) => {
  const { fields, append, remove, swap } = useFieldArray({ control, name })

  const handleSwap = (indexA: number, indexB: number, max: number) => {
    if (indexB >= 0 && indexB < max) {
      swap(indexA, indexB)
    }
  }

  return (
    <div className="space-y-1">
      <ul className="space-y-1">
        {fields.map((field, index) => {
          return (
            <li className="flex space-x-1" key={field.id}>
              <div className="flex-auto">{renderField(field, index)}</div>
              {isSortable && (
                <div className="flex flex-col">
                  <Button
                    style={{
                      padding: '0 0.5rem',
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0
                    }}
                    onClick={() => handleSwap(index, index - 1, fields.length)}
                  >
                    <TiArrowSortedUp size={16} />
                  </Button>
                  <Button
                    style={{
                      padding: '0 0.5rem',
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0
                    }}
                    onClick={() => handleSwap(index, index + 1, fields.length)}
                  >
                    <TiArrowSortedDown size={16} />
                  </Button>
                </div>
              )}
              {(minItems == null || fields.length > minItems) && (
                <div>
                  <Button onClick={() => remove(index)}>
                    <RiDeleteBin2Line size={18} />
                  </Button>
                </div>
              )}
            </li>
          )
        })}
      </ul>
      <Button onClick={() => append({})}>{addLabel}</Button>
    </div>
  )
}

export default FormList
