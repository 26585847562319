import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import RecipeList from './RecipeList'
import {
  useUpdateRecipeMutation,
  UpdateRecipe,
  CreateRecipe,
  Recipe,
  useCreateRecipeMutation,
  GetRecipesQuery,
  useDeleteRecipeMutation,
  useGetRecipesLazyQuery,
  useGetRecipesFullInfoLazyQuery
} from '../../types/graphql'
import { displayNotification } from '../Notification'
import RecipeCreation from './RecipeCreation'
import { GET_RECIPES } from '../../api/recipes'
import { recipeToUpdate } from '../../utils/recipes'
import RecipeDetail from './RecipeDetail'
import WarningModal from '../shared/WarningModal'

interface Props {
  setBreadcrumbRecipeName: (name: string) => void
}

const Recipes: React.FC<Props> = ({ setBreadcrumbRecipeName }) => {
  const { path } = useRouteMatch()

  const [
    getRecipes,
    { loading: queryRecipesLoading, error: queryRecipesError, data: recipes }
  ] = useGetRecipesLazyQuery()
  const [
    getRecipesFull,
    {
      loading: queryRecipeLoading,
      error: queryRecipeInfoError,
      data: recipeDetail
    }
  ] = useGetRecipesFullInfoLazyQuery()
  const [createRecipeMutation] = useCreateRecipeMutation({
    update: (cache, { data }) => {
      if (!data) {
        return null
      }
      const getCached = cache.readQuery<GetRecipesQuery>({ query: GET_RECIPES })
      const cached = getCached?.recipes || {
        edges: [],
        pageInfo: { endCursor: null, hasNextPage: false }
      }
      cache.evict({ fieldName: 'recipes', broadcast: false })
      cache.writeQuery<GetRecipesQuery>({
        query: GET_RECIPES,
        data: {
          recipes: {
            edges: [
              ...cached.edges,
              {
                node: data.createRecipe,
                cursor: data.createRecipe.created_at
              }
            ],
            pageInfo: {
              ...cached.pageInfo,
              endCursor: data.createRecipe.created_at
            }
          }
        }
      })
    }
  })
  const [deleteRecipeMutation] = useDeleteRecipeMutation({
    update: (cache, { data }) => {
      if (!data) {
        return null
      }
      const getCached = cache.readQuery<GetRecipesQuery>({
        query: GET_RECIPES
      })
      // cannot delete if no preceding recipes
      const cached = getCached!.recipes
      const newEdges = cached.edges.filter(
        edge => edge.node.id !== data.deleteRecipe
      )
      cache.evict({ fieldName: 'ingredients', broadcast: false })
      cache.writeQuery<GetRecipesQuery>({
        query: GET_RECIPES,
        data: {
          recipes: {
            edges: newEdges,
            pageInfo: {
              ...cached.pageInfo,
              endCursor:
                data.deleteRecipe ===
                cached.edges[cached.edges.length - 1].node.id
                  ? newEdges[newEdges.length - 1].cursor
                  : cached.edges[cached.edges.length - 1].cursor
            }
          }
        }
      })
    }
  })
  const [updateRecipeMutation] = useUpdateRecipeMutation()
  const [updating, setUpdating] = useState(false)
  const [modalContent, setModalContent] = useState<UpdateRecipe | null>(null)
  const [modalVisible, setModalVisible] = useState(false)

  const [deleteWarning, setDeleteWarning] = useState<Recipe | null>(null)
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (queryRecipeInfoError) {
      displayNotification(
        'error',
        'Could not fetch recipe.',
        queryRecipeInfoError.message || 'Unknown error'
      )
    }
  }, [queryRecipeInfoError])

  useEffect(() => {
    if (queryRecipesError) {
      displayNotification(
        'error',
        'Could not fetch recipes.',
        queryRecipesError.message || 'Unknown error'
      )
    }
  }, [queryRecipesError])

  useEffect(() => {
    if (recipeDetail != null && updating) {
      showModal(recipeToUpdate(recipeDetail))
    }
  }, [recipeDetail?.recipesFiltered[0].id]) // eslint-disable-line react-hooks/exhaustive-deps

  const showModal = (content: UpdateRecipe | null) => {
    setModalContent(content)
    setModalVisible(true)
  }

  const hideModal = () => {
    setUpdating(false)
    setModalContent(null)
    setModalVisible(false)
  }

  const onCreateRecipe = async (recipe: CreateRecipe) => {
    try {
      setSubmitting(true)
      await createRecipeMutation({ variables: { create: recipe } })
    } catch (error) {
      displayNotification('error', 'Recipe not created', error.message)
    } finally {
      setSubmitting(false)
      hideModal()
    }
  }

  const onUpdateRecipe = async (recipe: UpdateRecipe) => {
    try {
      setSubmitting(true)
      await updateRecipeMutation({ variables: { update: recipe } })
    } catch (error) {
      displayNotification('error', 'Recipe not updated', error.message)
    } finally {
      setSubmitting(false)
      hideModal()
    }
  }

  const onDeleteRecipe = async () => {
    try {
      setSubmitting(true)
      await deleteRecipeMutation({ variables: { id: deleteWarning!.id } })
    } catch (error) {
      displayNotification('error', 'Recipe not deleted', error.message)
    } finally {
      setSubmitting(false)
      setDeleteWarning(null)
    }
  }

  const onCancel = (changed: boolean) => {
    if (changed) {
      setUnsavedChanges(true)
    } else {
      hideModal()
    }
  }

  return (
    <>
      <Switch>
        <Route exact path={path}>
          <RecipeList
            queryRecipesLoading={queryRecipesLoading}
            getRecipes={getRecipes}
            handleCreate={() => showModal(null)}
            handleUpdate={recipe => {
              if (recipe.id === recipeDetail?.recipesFiltered[0].id) {
                showModal(recipeToUpdate(recipeDetail))
              } else {
                setUpdating(true)
                getRecipesFull({ variables: { filter: { ids: [recipe.id] } } })
              }
            }}
            handleDelete={recipe => setDeleteWarning(recipe)}
            recipes={recipes}
          />
        </Route>
        <Route exact path={`${path}/:recipeId`}>
          <RecipeDetail
            setBreadcrumbRecipeName={setBreadcrumbRecipeName}
            queryRecipeLoading={queryRecipeLoading}
            getRecipeInfo={getRecipesFull}
            handleUpdate={() => showModal(recipeToUpdate(recipeDetail!))}
            recipe={recipeDetail}
          />
        </Route>
      </Switch>
      <RecipeCreation
        submitting={submitting}
        initialValues={modalContent}
        visible={modalVisible}
        onCreate={onCreateRecipe}
        onUpdate={onUpdateRecipe}
        onCancel={onCancel}
      />
      <WarningModal
        submitting={submitting}
        visible={!!deleteWarning}
        title="Suppression d'une recette"
        bodyText={`Es-tu sûr de vouloir supprimer ${_.capitalize(
          deleteWarning?.name
        )}`}
        onDismiss={() => setDeleteWarning(null)}
        onConfirm={() => onDeleteRecipe()}
      />
      <WarningModal
        visible={!!unsavedChanges}
        title="Modification en cours"
        bodyText="Toutes les modifications non enregistrées seront perdues."
        onDismiss={() => setUnsavedChanges(false)}
        onConfirm={() => {
          setUnsavedChanges(false)
          hideModal()
        }}
      />
    </>
  )
}

export default Recipes
