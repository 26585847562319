import React from 'react'
import { RecipeStep } from '../../../types/graphql'
import styles from './PreparationSteps.module.css'

const PreparationSteps: React.FC<{ items: RecipeStep[] }> = ({ items }) => {
  return (
    <div>
      {items.map((item, i) => {
        return (
          <div className={styles.stepWrapper} key={i}>
            <p className={styles.stepNumber}>{i + 1}</p>
            <p className={styles.stepTitle}>{item.name}</p>
            {item.description.split('\n\n').map((li, i) => (
              <div className="flex" key={i}>
                <div className={styles.bullet}>
                  <p className={styles.dot}>●</p>
                </div>
                <div>
                  {li.split('\n').map((str, i) => (
                    <p className={styles.description} key={i}>
                      {str}
                    </p>
                  ))}
                </div>
              </div>
            ))}
            {item.img && item.img.length > 0 && (
              <div className={styles.stepImgContainer}>
                {item.img.map((url, i) => (
                  <div className={styles.stepImgWrapper} key={i}>
                    {url !== '' && (
                      <img
                        loading="lazy"
                        className={styles.stepImg}
                        crossOrigin="anonymous"
                        src={url}
                        alt=""
                      />
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default PreparationSteps
