import React from 'react'
import { ReactComponent as Logo } from '../../../assets/svg/logo-dark.svg'
import { ReactComponent as Instagram } from '../../../assets/svg/instagram.svg'
import { ReactComponent as Tiktok } from '../../../assets/svg/tiktok.svg'
import styles from './Page.module.css'

const Page: React.FC<{ id: string; verso?: boolean }> = ({
  id,
  verso,
  children
}) => {
  return (
    <div id={id} className={styles.container}>
      {children}
      {verso && (
        <div className={styles.footerWrapper}>
          <div className={styles.blankSpace}></div>
          <div className={styles.footer}>
            <p className={styles.socialText}>
              N’hésitez pas à partager vos photos sur les réseaux sociaux
              accompagnées de la mention{' '}
              <span className={styles.highlight}>#Mukbox</span> !
            </p>
            <div className={styles.socialIcon}>
              <Instagram style={{ width: 20, height: 20 }} />
            </div>
            <div className={styles.socialIcon}>
              <Tiktok style={{ width: 20, height: 20 }} />
            </div>
          </div>
        </div>
      )}
      <div className={styles.logoWrapper}>
        <Logo style={{ width: 96, height: 16 }} />
      </div>
    </div>
  )
}

export default Page
