import React from 'react'
import { FiAlertTriangle, FiInfo } from 'react-icons/fi'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'
import { getEventColor } from '../../utils'

interface Props {
  title?: string
  message?: React.ReactNode
  type: 'error' | 'warning' | 'success' | 'info'
}

const Alert: React.FC<Props> = ({ type, title, message }) => {
  const color = getEventColor(type)
  const renderIcon = () => {
    switch (type) {
      case 'error':
        return <FiAlertTriangle className={`h-6 w-6 text-${color}-500 mr-4`} />
      case 'warning':
        return <FiAlertTriangle className={`h-6 w-6 text-${color}-500 mr-4`} />
      case 'success':
        return (
          <IoMdCheckmarkCircleOutline
            className={`h-6 w-6 text-${color}-500 mr-4`}
          />
        )
      case 'info':
        return <FiInfo className={`h-6 w-6 text-${color}-500 mr-4`} />
      default:
        return null
    }
  }
  return (
    <div
      className={`bg-${color}-100 border-l-4 border-${color}-500 rounded text-${color}-500 px-4 py-3`}
    >
      <div className="flex">
        <div className="py-1">{renderIcon()}</div>
        <div>
          {title && <p className="font-bold font-body text-sm">{title}</p>}
          {message && <div className="font-body text-xs">{message}</div>}
        </div>
      </div>
    </div>
  )
}

export default Alert
