import React from 'react'
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted
} from 'react-icons/ti'
import { useTable, useSortBy, Row, useRowSelect } from 'react-table'

interface Props {
  headers?: boolean
  columns: any
  data: any
  handleRowClick?: (row: Row) => void
}

const Table: React.FC<Props> = ({
  headers = true,
  columns,
  data,
  handleRowClick
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data
    },
    useSortBy,
    useRowSelect
  )

  return (
    <div className="rounded border border-gray-300">
      <table
        className="overflow-hidden rounded w-full table-auto"
        {...getTableProps()}
      >
        {headers && (
          <thead>
            {headerGroups.map(headerGroup => (
              <tr
                className="bg-gray-100"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((header: any) => {
                  return (
                    <th
                      className="font-body font-bold text-left text-xs text-gray-900 px-4 py-2"
                      {...header.getHeaderProps(header.getSortByToggleProps())}
                    >
                      <div className="flex items-center space-x-1">
                        <span>{header.render('Header')}</span>
                        {header.canSort ? (
                          header.isSorted ? (
                            header.isSortedDesc ? (
                              <TiArrowSortedDown size={12} />
                            ) : (
                              <TiArrowSortedUp size={12} />
                            )
                          ) : (
                            <TiArrowUnsorted size={12} />
                          )
                        ) : null}
                      </div>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
        )}
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr
                className={`${handleRowClick ? 'cursor-pointer' : ''}`}
                onClick={() => {
                  if (handleRowClick) {
                    handleRowClick(row)
                  }
                }}
                {...row.getRowProps()}
              >
                {row.cells.map(cell => {
                  return (
                    <td
                      className="font-body text-left text-xs text-gray-900 border-t border-gray-300 px-4 py-2"
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Table
