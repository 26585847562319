import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  small?: boolean
  primary?: boolean
  loading?: boolean
  submit?: boolean
}

const Button: React.FC<ButtonProps> = ({
  primary,
  small,
  loading = false,
  submit = false,
  children,
  ...rest
}) => {
  return (
    <button
      type={submit ? 'submit' : 'button'}
      className={`${
        primary || submit
          ? 'border-orange-500 bg-orange-500 text-white hover:bg-orange-600'
          : 'border-gray-300 bg-gray-100 text-gray-700 hover:bg-gray-200'
      } ${
        small ? 'px-2 py-1' : 'px-4 py-2'
      } flex items-center space-x-2 border rounded font-title text-xs outline-none focus:outline-none cursor-pointer transition-all duration-300`}
      disabled={loading}
      {...(rest as any)}
    >
      {loading && (
        <ClipLoader size={12} color={primary || submit ? '#fff' : '#4A5568'} />
      )}
      <div>{children}</div>
    </button>
  )
}

export { Button }
