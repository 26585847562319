import usersMock from '../mocks/users.json'
import { Allergene, Diet } from '../types/graphql'

export interface User {
  id: number
  name: string
  firstname: string
  delivery_information: {
    address: string
    city: string
    zipcode: string
    country: string
  }
  allergenes: Allergene[]
  diet_constraints: Diet[]
  subscription_plan: {
    updated_at: number
    created_at: number
    plan: string
  }
}

export const getUsers = () => {
  return usersMock as User[]
}
