import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
};


export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['DateTime']>;
  hasNextPage: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']>;
  ingredients: IngredientConnection;
  recipes: RecipeConnection;
  recipesFiltered: Array<Recipe>;
};


export type QueryIngredientsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['DateTime']>;
};


export type QueryRecipesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['DateTime']>;
};


export type QueryRecipesFilteredArgs = {
  filter: RecipeFilter;
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  createIngredient: Ingredient;
  updateIngredient: Ingredient;
  deleteIngredient: Scalars['ID'];
  createRecipe: Recipe;
  updateRecipe: Recipe;
  deleteRecipe: Scalars['ID'];
};


export type MutationCreateIngredientArgs = {
  create: CreateIngredient;
};


export type MutationUpdateIngredientArgs = {
  update: UpdateIngredient;
};


export type MutationDeleteIngredientArgs = {
  id: Scalars['ID'];
};


export type MutationCreateRecipeArgs = {
  create: CreateRecipe;
};


export type MutationUpdateRecipeArgs = {
  update: UpdateRecipe;
};


export type MutationDeleteRecipeArgs = {
  id: Scalars['ID'];
};

export type IngredientConnection = {
  __typename?: 'IngredientConnection';
  edges: Array<IngredientEdge>;
  pageInfo: PageInfo;
};

export type IngredientEdge = {
  __typename?: 'IngredientEdge';
  node: Ingredient;
  cursor: Scalars['DateTime'];
};

export type Ingredient = {
  __typename?: 'Ingredient';
  id: Scalars['ID'];
  name: Scalars['String'];
  img_url?: Maybe<Scalars['String']>;
  allergenes?: Maybe<Array<Allergene>>;
  diet_constraints?: Maybe<Array<Diet>>;
  created_at: Scalars['DateTime'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type CreateIngredient = {
  name: Scalars['String'];
  img_url?: Maybe<Scalars['String']>;
  allergenes?: Maybe<Array<Allergene>>;
  diet_constraints?: Maybe<Array<Diet>>;
};

export type UpdateIngredient = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  img_url?: Maybe<Scalars['String']>;
  allergenes?: Maybe<Array<Allergene>>;
  diet_constraints?: Maybe<Array<Diet>>;
};

export enum Allergene {
  Gluten = 'GLUTEN',
  Crustaceans = 'CRUSTACEANS',
  Eggs = 'EGGS',
  Fish = 'FISH',
  Peanuts = 'PEANUTS',
  Soybeans = 'SOYBEANS',
  Milk = 'MILK',
  Nuts = 'NUTS',
  Celery = 'CELERY',
  Mustard = 'MUSTARD',
  SesameSeeds = 'SESAME_SEEDS',
  Sulphur = 'SULPHUR',
  Lupin = 'LUPIN',
  Molluscs = 'MOLLUSCS'
}

export enum Diet {
  Vegan = 'VEGAN',
  Vegetarian = 'VEGETARIAN',
  Pescaterian = 'PESCATERIAN'
}

export type RecipeConnection = {
  __typename?: 'RecipeConnection';
  edges: Array<RecipeEdge>;
  pageInfo: PageInfo;
};

export type RecipeEdge = {
  __typename?: 'RecipeEdge';
  node: Recipe;
  cursor: Scalars['DateTime'];
};

export type Recipe = {
  __typename?: 'Recipe';
  id: Scalars['ID'];
  name: Scalars['String'];
  original_name?: Maybe<Scalars['String']>;
  img_url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  servings_info?: Maybe<Scalars['String']>;
  type: RecipeType;
  country: RecipeCountry;
  cook_duration?: Maybe<Scalars['Int']>;
  prep_duration?: Maybe<Scalars['Int']>;
  ingredients: Array<IngredientQty>;
  ustensils?: Maybe<Array<Scalars['String']>>;
  misc?: Maybe<Array<Scalars['String']>>;
  steps?: Maybe<Array<RecipeStep>>;
  good_start?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type RecipeFilter = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CreateRecipe = {
  name: Scalars['String'];
  original_name?: Maybe<Scalars['String']>;
  img_url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  servings_info?: Maybe<Scalars['String']>;
  type: RecipeType;
  country: RecipeCountry;
  cook_duration?: Maybe<Scalars['Int']>;
  prep_duration?: Maybe<Scalars['Int']>;
  ingredients: Array<IngredientQtyInput>;
  ustensils?: Maybe<Array<Scalars['String']>>;
  misc?: Maybe<Array<Scalars['String']>>;
  steps?: Maybe<Array<RecipeStepInput>>;
  good_start?: Maybe<Scalars['String']>;
};

export type UpdateRecipe = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  original_name?: Maybe<Scalars['String']>;
  img_url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  servings_info?: Maybe<Scalars['String']>;
  type?: Maybe<RecipeType>;
  country?: Maybe<RecipeCountry>;
  cook_duration?: Maybe<Scalars['Int']>;
  prep_duration?: Maybe<Scalars['Int']>;
  ingredients?: Maybe<Array<IngredientQtyInput>>;
  ustensils?: Maybe<Array<Scalars['String']>>;
  misc?: Maybe<Array<Scalars['String']>>;
  steps?: Maybe<Array<RecipeStepInput>>;
  good_start?: Maybe<Scalars['String']>;
};

export type IngredientQtyInput = {
  ingredient_id: Scalars['ID'];
  quantity: QtyPerBoxSizeInput;
  unit: RecipeIngredientUnit;
};

export type RecipeStepInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  img?: Maybe<Array<Scalars['String']>>;
};

export type IngredientQty = {
  __typename?: 'IngredientQty';
  ingredient: Ingredient;
  quantity: QtyPerBoxSize;
  unit: RecipeIngredientUnit;
};

export type RecipeStep = {
  __typename?: 'RecipeStep';
  name: Scalars['String'];
  description: Scalars['String'];
  img?: Maybe<Array<Scalars['String']>>;
};

export type QtyPerBoxSize = {
  __typename?: 'QtyPerBoxSize';
  box_two: Scalars['Float'];
  box_four?: Maybe<Scalars['Float']>;
  box_six?: Maybe<Scalars['Float']>;
};

export type QtyPerBoxSizeInput = {
  box_two: Scalars['Float'];
  box_four?: Maybe<Scalars['Float']>;
  box_six?: Maybe<Scalars['Float']>;
};

export enum RecipeType {
  Starter = 'STARTER',
  Dish = 'DISH',
  Dessert = 'DESSERT'
}

export enum RecipeCountry {
  China = 'CHINA',
  SouthKorea = 'SOUTH_KOREA',
  Thailand = 'THAILAND',
  Vietnam = 'VIETNAM',
  Cambodia = 'CAMBODIA',
  Japan = 'JAPAN',
  Laos = 'LAOS',
  India = 'INDIA',
  Philippines = 'PHILIPPINES'
}

export enum RecipeIngredientUnit {
  Piece = 'PIECE',
  Ml = 'ML',
  Cl = 'CL',
  L = 'L',
  G = 'G',
  Kg = 'KG',
  Tsp = 'TSP',
  Tbsp = 'TBSP',
  Branch = 'BRANCH',
  Leaf = 'LEAF'
}

export type GetIngredientsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['DateTime']>;
}>;


export type GetIngredientsQuery = (
  { __typename?: 'Query' }
  & { ingredients: (
    { __typename?: 'IngredientConnection' }
    & { edges: Array<(
      { __typename?: 'IngredientEdge' }
      & Pick<IngredientEdge, 'cursor'>
      & { node: (
        { __typename?: 'Ingredient' }
        & Pick<Ingredient, 'id' | 'name' | 'img_url' | 'allergenes' | 'diet_constraints'>
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type CreateIngredientMutationVariables = Exact<{
  create: CreateIngredient;
}>;


export type CreateIngredientMutation = (
  { __typename?: 'Mutation' }
  & { createIngredient: (
    { __typename?: 'Ingredient' }
    & Pick<Ingredient, 'id' | 'name' | 'img_url' | 'allergenes' | 'diet_constraints' | 'created_at'>
  ) }
);

export type UpdateIngredientMutationVariables = Exact<{
  update: UpdateIngredient;
}>;


export type UpdateIngredientMutation = (
  { __typename?: 'Mutation' }
  & { updateIngredient: (
    { __typename?: 'Ingredient' }
    & Pick<Ingredient, 'id' | 'name' | 'img_url' | 'allergenes' | 'diet_constraints'>
  ) }
);

export type DeleteIngredientMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteIngredientMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteIngredient'>
);

export type GetRecipesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['DateTime']>;
}>;


export type GetRecipesQuery = (
  { __typename?: 'Query' }
  & { recipes: (
    { __typename?: 'RecipeConnection' }
    & { edges: Array<(
      { __typename?: 'RecipeEdge' }
      & Pick<RecipeEdge, 'cursor'>
      & { node: (
        { __typename?: 'Recipe' }
        & Pick<Recipe, 'id' | 'name' | 'original_name' | 'type' | 'country' | 'cook_duration' | 'prep_duration'>
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type GetRecipesShortInfoQueryVariables = Exact<{
  filter: RecipeFilter;
}>;


export type GetRecipesShortInfoQuery = (
  { __typename?: 'Query' }
  & { recipesFiltered: Array<(
    { __typename?: 'Recipe' }
    & Pick<Recipe, 'id' | 'name' | 'type' | 'country' | 'cook_duration' | 'prep_duration'>
    & { ingredients: Array<(
      { __typename?: 'IngredientQty' }
      & Pick<IngredientQty, 'unit'>
      & { ingredient: (
        { __typename?: 'Ingredient' }
        & Pick<Ingredient, 'id' | 'name' | 'allergenes' | 'diet_constraints'>
      ), quantity: (
        { __typename?: 'QtyPerBoxSize' }
        & Pick<QtyPerBoxSize, 'box_two' | 'box_four' | 'box_six'>
      ) }
    )> }
  )> }
);

export type GetRecipesFullInfoQueryVariables = Exact<{
  filter: RecipeFilter;
}>;


export type GetRecipesFullInfoQuery = (
  { __typename?: 'Query' }
  & { recipesFiltered: Array<(
    { __typename?: 'Recipe' }
    & Pick<Recipe, 'id' | 'name' | 'original_name' | 'img_url' | 'description' | 'servings_info' | 'type' | 'country' | 'cook_duration' | 'prep_duration' | 'ustensils' | 'misc' | 'good_start'>
    & { ingredients: Array<(
      { __typename?: 'IngredientQty' }
      & Pick<IngredientQty, 'unit'>
      & { ingredient: (
        { __typename?: 'Ingredient' }
        & Pick<Ingredient, 'id' | 'name' | 'img_url' | 'allergenes' | 'diet_constraints'>
      ), quantity: (
        { __typename?: 'QtyPerBoxSize' }
        & Pick<QtyPerBoxSize, 'box_two' | 'box_four' | 'box_six'>
      ) }
    )>, steps?: Maybe<Array<(
      { __typename?: 'RecipeStep' }
      & Pick<RecipeStep, 'name' | 'description' | 'img'>
    )>> }
  )> }
);

export type CreateRecipeMutationVariables = Exact<{
  create: CreateRecipe;
}>;


export type CreateRecipeMutation = (
  { __typename?: 'Mutation' }
  & { createRecipe: (
    { __typename?: 'Recipe' }
    & Pick<Recipe, 'id' | 'name' | 'original_name' | 'img_url' | 'description' | 'servings_info' | 'type' | 'country' | 'cook_duration' | 'prep_duration' | 'ustensils' | 'misc' | 'good_start' | 'created_at'>
    & { ingredients: Array<(
      { __typename?: 'IngredientQty' }
      & Pick<IngredientQty, 'unit'>
      & { ingredient: (
        { __typename?: 'Ingredient' }
        & Pick<Ingredient, 'name'>
      ), quantity: (
        { __typename?: 'QtyPerBoxSize' }
        & Pick<QtyPerBoxSize, 'box_two' | 'box_four' | 'box_six'>
      ) }
    )>, steps?: Maybe<Array<(
      { __typename?: 'RecipeStep' }
      & Pick<RecipeStep, 'name' | 'description' | 'img'>
    )>> }
  ) }
);

export type UpdateRecipeMutationVariables = Exact<{
  update: UpdateRecipe;
}>;


export type UpdateRecipeMutation = (
  { __typename?: 'Mutation' }
  & { updateRecipe: (
    { __typename?: 'Recipe' }
    & Pick<Recipe, 'id' | 'name' | 'original_name' | 'img_url' | 'description' | 'servings_info' | 'type' | 'country' | 'cook_duration' | 'prep_duration' | 'ustensils' | 'misc' | 'good_start'>
    & { ingredients: Array<(
      { __typename?: 'IngredientQty' }
      & Pick<IngredientQty, 'unit'>
      & { ingredient: (
        { __typename?: 'Ingredient' }
        & Pick<Ingredient, 'name'>
      ), quantity: (
        { __typename?: 'QtyPerBoxSize' }
        & Pick<QtyPerBoxSize, 'box_two' | 'box_four' | 'box_six'>
      ) }
    )>, steps?: Maybe<Array<(
      { __typename?: 'RecipeStep' }
      & Pick<RecipeStep, 'name' | 'description' | 'img'>
    )>> }
  ) }
);

export type DeleteRecipeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteRecipeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteRecipe'>
);


export const GetIngredientsDocument = gql`
    query GetIngredients($first: Int, $after: DateTime) {
  ingredients(first: $first, after: $after) {
    edges {
      node {
        id
        name
        img_url
        allergenes
        diet_constraints
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetIngredientsQuery__
 *
 * To run a query within a React component, call `useGetIngredientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIngredientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIngredientsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetIngredientsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetIngredientsQuery, GetIngredientsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetIngredientsQuery, GetIngredientsQueryVariables>(GetIngredientsDocument, baseOptions);
      }
export function useGetIngredientsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetIngredientsQuery, GetIngredientsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetIngredientsQuery, GetIngredientsQueryVariables>(GetIngredientsDocument, baseOptions);
        }
export type GetIngredientsQueryHookResult = ReturnType<typeof useGetIngredientsQuery>;
export type GetIngredientsLazyQueryHookResult = ReturnType<typeof useGetIngredientsLazyQuery>;
export type GetIngredientsQueryResult = ApolloReactCommon.QueryResult<GetIngredientsQuery, GetIngredientsQueryVariables>;
export const CreateIngredientDocument = gql`
    mutation CreateIngredient($create: CreateIngredient!) {
  createIngredient(create: $create) {
    id
    name
    img_url
    allergenes
    diet_constraints
    created_at
  }
}
    `;
export type CreateIngredientMutationFn = ApolloReactCommon.MutationFunction<CreateIngredientMutation, CreateIngredientMutationVariables>;

/**
 * __useCreateIngredientMutation__
 *
 * To run a mutation, you first call `useCreateIngredientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIngredientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIngredientMutation, { data, loading, error }] = useCreateIngredientMutation({
 *   variables: {
 *      create: // value for 'create'
 *   },
 * });
 */
export function useCreateIngredientMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateIngredientMutation, CreateIngredientMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateIngredientMutation, CreateIngredientMutationVariables>(CreateIngredientDocument, baseOptions);
      }
export type CreateIngredientMutationHookResult = ReturnType<typeof useCreateIngredientMutation>;
export type CreateIngredientMutationResult = ApolloReactCommon.MutationResult<CreateIngredientMutation>;
export type CreateIngredientMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateIngredientMutation, CreateIngredientMutationVariables>;
export const UpdateIngredientDocument = gql`
    mutation UpdateIngredient($update: UpdateIngredient!) {
  updateIngredient(update: $update) {
    id
    name
    img_url
    allergenes
    diet_constraints
  }
}
    `;
export type UpdateIngredientMutationFn = ApolloReactCommon.MutationFunction<UpdateIngredientMutation, UpdateIngredientMutationVariables>;

/**
 * __useUpdateIngredientMutation__
 *
 * To run a mutation, you first call `useUpdateIngredientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIngredientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIngredientMutation, { data, loading, error }] = useUpdateIngredientMutation({
 *   variables: {
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateIngredientMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateIngredientMutation, UpdateIngredientMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateIngredientMutation, UpdateIngredientMutationVariables>(UpdateIngredientDocument, baseOptions);
      }
export type UpdateIngredientMutationHookResult = ReturnType<typeof useUpdateIngredientMutation>;
export type UpdateIngredientMutationResult = ApolloReactCommon.MutationResult<UpdateIngredientMutation>;
export type UpdateIngredientMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateIngredientMutation, UpdateIngredientMutationVariables>;
export const DeleteIngredientDocument = gql`
    mutation DeleteIngredient($id: ID!) {
  deleteIngredient(id: $id)
}
    `;
export type DeleteIngredientMutationFn = ApolloReactCommon.MutationFunction<DeleteIngredientMutation, DeleteIngredientMutationVariables>;

/**
 * __useDeleteIngredientMutation__
 *
 * To run a mutation, you first call `useDeleteIngredientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIngredientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIngredientMutation, { data, loading, error }] = useDeleteIngredientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteIngredientMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteIngredientMutation, DeleteIngredientMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteIngredientMutation, DeleteIngredientMutationVariables>(DeleteIngredientDocument, baseOptions);
      }
export type DeleteIngredientMutationHookResult = ReturnType<typeof useDeleteIngredientMutation>;
export type DeleteIngredientMutationResult = ApolloReactCommon.MutationResult<DeleteIngredientMutation>;
export type DeleteIngredientMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteIngredientMutation, DeleteIngredientMutationVariables>;
export const GetRecipesDocument = gql`
    query GetRecipes($first: Int, $after: DateTime) {
  recipes(first: $first, after: $after) {
    edges {
      node {
        id
        name
        original_name
        type
        country
        cook_duration
        prep_duration
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetRecipesQuery__
 *
 * To run a query within a React component, call `useGetRecipesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetRecipesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRecipesQuery, GetRecipesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRecipesQuery, GetRecipesQueryVariables>(GetRecipesDocument, baseOptions);
      }
export function useGetRecipesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRecipesQuery, GetRecipesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRecipesQuery, GetRecipesQueryVariables>(GetRecipesDocument, baseOptions);
        }
export type GetRecipesQueryHookResult = ReturnType<typeof useGetRecipesQuery>;
export type GetRecipesLazyQueryHookResult = ReturnType<typeof useGetRecipesLazyQuery>;
export type GetRecipesQueryResult = ApolloReactCommon.QueryResult<GetRecipesQuery, GetRecipesQueryVariables>;
export const GetRecipesShortInfoDocument = gql`
    query GetRecipesShortInfo($filter: RecipeFilter!) {
  recipesFiltered(filter: $filter) {
    id
    name
    type
    country
    cook_duration
    prep_duration
    ingredients {
      ingredient {
        id
        name
        allergenes
        diet_constraints
      }
      quantity {
        box_two
        box_four
        box_six
      }
      unit
    }
  }
}
    `;

/**
 * __useGetRecipesShortInfoQuery__
 *
 * To run a query within a React component, call `useGetRecipesShortInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipesShortInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipesShortInfoQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetRecipesShortInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRecipesShortInfoQuery, GetRecipesShortInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRecipesShortInfoQuery, GetRecipesShortInfoQueryVariables>(GetRecipesShortInfoDocument, baseOptions);
      }
export function useGetRecipesShortInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRecipesShortInfoQuery, GetRecipesShortInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRecipesShortInfoQuery, GetRecipesShortInfoQueryVariables>(GetRecipesShortInfoDocument, baseOptions);
        }
export type GetRecipesShortInfoQueryHookResult = ReturnType<typeof useGetRecipesShortInfoQuery>;
export type GetRecipesShortInfoLazyQueryHookResult = ReturnType<typeof useGetRecipesShortInfoLazyQuery>;
export type GetRecipesShortInfoQueryResult = ApolloReactCommon.QueryResult<GetRecipesShortInfoQuery, GetRecipesShortInfoQueryVariables>;
export const GetRecipesFullInfoDocument = gql`
    query GetRecipesFullInfo($filter: RecipeFilter!) {
  recipesFiltered(filter: $filter) {
    id
    name
    original_name
    img_url
    description
    servings_info
    type
    country
    cook_duration
    prep_duration
    ingredients {
      ingredient {
        id
        name
        img_url
        allergenes
        diet_constraints
      }
      quantity {
        box_two
        box_four
        box_six
      }
      unit
    }
    ustensils
    misc
    good_start
    steps {
      name
      description
      img
    }
  }
}
    `;

/**
 * __useGetRecipesFullInfoQuery__
 *
 * To run a query within a React component, call `useGetRecipesFullInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipesFullInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipesFullInfoQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetRecipesFullInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRecipesFullInfoQuery, GetRecipesFullInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRecipesFullInfoQuery, GetRecipesFullInfoQueryVariables>(GetRecipesFullInfoDocument, baseOptions);
      }
export function useGetRecipesFullInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRecipesFullInfoQuery, GetRecipesFullInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRecipesFullInfoQuery, GetRecipesFullInfoQueryVariables>(GetRecipesFullInfoDocument, baseOptions);
        }
export type GetRecipesFullInfoQueryHookResult = ReturnType<typeof useGetRecipesFullInfoQuery>;
export type GetRecipesFullInfoLazyQueryHookResult = ReturnType<typeof useGetRecipesFullInfoLazyQuery>;
export type GetRecipesFullInfoQueryResult = ApolloReactCommon.QueryResult<GetRecipesFullInfoQuery, GetRecipesFullInfoQueryVariables>;
export const CreateRecipeDocument = gql`
    mutation CreateRecipe($create: CreateRecipe!) {
  createRecipe(create: $create) {
    id
    name
    original_name
    img_url
    description
    servings_info
    type
    country
    cook_duration
    prep_duration
    ingredients {
      ingredient {
        name
      }
      quantity {
        box_two
        box_four
        box_six
      }
      unit
    }
    ustensils
    misc
    good_start
    steps {
      name
      description
      img
    }
    created_at
  }
}
    `;
export type CreateRecipeMutationFn = ApolloReactCommon.MutationFunction<CreateRecipeMutation, CreateRecipeMutationVariables>;

/**
 * __useCreateRecipeMutation__
 *
 * To run a mutation, you first call `useCreateRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecipeMutation, { data, loading, error }] = useCreateRecipeMutation({
 *   variables: {
 *      create: // value for 'create'
 *   },
 * });
 */
export function useCreateRecipeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRecipeMutation, CreateRecipeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRecipeMutation, CreateRecipeMutationVariables>(CreateRecipeDocument, baseOptions);
      }
export type CreateRecipeMutationHookResult = ReturnType<typeof useCreateRecipeMutation>;
export type CreateRecipeMutationResult = ApolloReactCommon.MutationResult<CreateRecipeMutation>;
export type CreateRecipeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRecipeMutation, CreateRecipeMutationVariables>;
export const UpdateRecipeDocument = gql`
    mutation UpdateRecipe($update: UpdateRecipe!) {
  updateRecipe(update: $update) {
    id
    name
    original_name
    img_url
    description
    servings_info
    type
    country
    cook_duration
    prep_duration
    ingredients {
      ingredient {
        name
      }
      quantity {
        box_two
        box_four
        box_six
      }
      unit
    }
    ustensils
    misc
    good_start
    steps {
      name
      description
      img
    }
  }
}
    `;
export type UpdateRecipeMutationFn = ApolloReactCommon.MutationFunction<UpdateRecipeMutation, UpdateRecipeMutationVariables>;

/**
 * __useUpdateRecipeMutation__
 *
 * To run a mutation, you first call `useUpdateRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecipeMutation, { data, loading, error }] = useUpdateRecipeMutation({
 *   variables: {
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateRecipeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRecipeMutation, UpdateRecipeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRecipeMutation, UpdateRecipeMutationVariables>(UpdateRecipeDocument, baseOptions);
      }
export type UpdateRecipeMutationHookResult = ReturnType<typeof useUpdateRecipeMutation>;
export type UpdateRecipeMutationResult = ApolloReactCommon.MutationResult<UpdateRecipeMutation>;
export type UpdateRecipeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRecipeMutation, UpdateRecipeMutationVariables>;
export const DeleteRecipeDocument = gql`
    mutation DeleteRecipe($id: ID!) {
  deleteRecipe(id: $id)
}
    `;
export type DeleteRecipeMutationFn = ApolloReactCommon.MutationFunction<DeleteRecipeMutation, DeleteRecipeMutationVariables>;

/**
 * __useDeleteRecipeMutation__
 *
 * To run a mutation, you first call `useDeleteRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRecipeMutation, { data, loading, error }] = useDeleteRecipeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRecipeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRecipeMutation, DeleteRecipeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRecipeMutation, DeleteRecipeMutationVariables>(DeleteRecipeDocument, baseOptions);
      }
export type DeleteRecipeMutationHookResult = ReturnType<typeof useDeleteRecipeMutation>;
export type DeleteRecipeMutationResult = ApolloReactCommon.MutationResult<DeleteRecipeMutation>;
export type DeleteRecipeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRecipeMutation, DeleteRecipeMutationVariables>;