import React from 'react'
import ReactDOM from 'react-dom'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import './assets/styles/index.css'
import 'rc-notification/assets/index.css'
import 'rc-dialog/assets/index.css'
import './assets/styles/tailwind.output.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { initNotificationhandler } from './components/Notification'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

firebase.initializeApp({
  apiKey: 'AIzaSyCcC4VSkw48wBLfG1h4ReLTfGcqsIotCCM',
  authDomain: 'mukbox-admin.firebaseapp.com',
  projectId: 'mukbox-admin',
  appId: '1:1073630818729:web:ba6efb685f5462e21fd4b8'
})

initNotificationhandler()

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV || 'development'
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
