import React from 'react'
import { computeTextColorFromBgColor } from '../../utils'

interface Props {
  color: string
}

const Tag: React.FC<Props> = ({ color, children }) => {
  const textColor = computeTextColorFromBgColor({
    bgColor: color,
    dark: 'gray-900',
    light: 'white'
  })
  return (
    <div
      className={`text-2xs font-body rounded py-1 px-2 whitespace-no-wrap text-${textColor}`}
      style={{ backgroundColor: color }}
    >
      {children}
    </div>
  )
}

export default Tag
