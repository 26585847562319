import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import IngredientList from './IngredientList'
import {
  useGetIngredientsLazyQuery,
  useCreateIngredientMutation,
  GetIngredientsQuery,
  useDeleteIngredientMutation,
  useUpdateIngredientMutation,
  Ingredient,
  UpdateIngredient,
  CreateIngredient
} from '../../types/graphql'
import { GET_INGREDIENTS } from '../../api/ingredients'
import { displayNotification } from '../Notification'
import IngredientCreation from './IngredientCreation'
import WarningModal from '../shared/WarningModal'

interface Props {}

const Ingredients: React.FC<Props> = () => {
  const { path } = useRouteMatch()

  const [
    getIngredients,
    {
      loading: queryIngredientsLoading,
      error: queryIngredientsError,
      data: ingredients
    }
  ] = useGetIngredientsLazyQuery()
  const [createIngredient] = useCreateIngredientMutation({
    update: (cache, { data }) => {
      if (!data) {
        return null
      }
      const getCached = cache.readQuery<GetIngredientsQuery>({
        query: GET_INGREDIENTS
      })
      const cached = getCached?.ingredients || {
        edges: [],
        pageInfo: { endCursor: null, hasNextPage: false }
      }
      cache.evict({ fieldName: 'ingredients', broadcast: false })
      cache.writeQuery<GetIngredientsQuery>({
        query: GET_INGREDIENTS,
        data: {
          ingredients: {
            edges: [
              ...cached.edges,
              {
                node: data.createIngredient,
                cursor: data.createIngredient.created_at
              }
            ],
            pageInfo: {
              ...cached.pageInfo,
              endCursor: data.createIngredient.created_at
            }
          }
        }
      })
    }
  })
  const [deleteIngredient] = useDeleteIngredientMutation({
    update: (cache, { data }) => {
      if (!data) {
        return null
      }
      const getCached = cache.readQuery<GetIngredientsQuery>({
        query: GET_INGREDIENTS
      })
      // cannot delete if no preceding ingredients
      const cached = getCached!.ingredients
      const newEdges = cached.edges.filter(
        edge => edge.node.id !== data.deleteIngredient
      )
      cache.evict({ fieldName: 'ingredients', broadcast: false })
      cache.writeQuery<GetIngredientsQuery>({
        query: GET_INGREDIENTS,
        data: {
          ingredients: {
            edges: newEdges,
            pageInfo: {
              ...cached.pageInfo,
              endCursor:
                data.deleteIngredient ===
                cached.edges[cached.edges.length - 1].node.id
                  ? newEdges[newEdges.length - 1].cursor
                  : cached.edges[cached.edges.length - 1].cursor
            }
          }
        }
      })
    }
  })
  const [updateIngredient] = useUpdateIngredientMutation()
  const [modalContent, setModalContent] = useState<UpdateIngredient | null>(
    null
  )
  const [modalVisible, setModalVisible] = useState(false)

  const [deleteWarning, setDeleteWarning] = useState<Ingredient | null>(null)
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (queryIngredientsError) {
      displayNotification(
        'error',
        'Could not fetch ingredients.',
        queryIngredientsError.message || 'Unknown error'
      )
    }
  }, [queryIngredientsError])

  const showModal = (content: UpdateIngredient | null) => {
    setModalContent(content)
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalContent(null)
    setModalVisible(false)
  }

  const onCreateIngredient = async (ingredient: CreateIngredient) => {
    try {
      setSubmitting(true)
      await createIngredient({ variables: { create: ingredient } })
    } catch (error) {
      displayNotification('error', 'Ingredient not created', error.message)
    } finally {
      setSubmitting(false)
      hideModal()
    }
  }

  const onUpdateIngredient = async (ingredient: UpdateIngredient) => {
    try {
      setSubmitting(true)
      await updateIngredient({ variables: { update: ingredient } })
    } catch (error) {
      displayNotification('error', 'Ingredient not updated', error.message)
    } finally {
      setSubmitting(false)
      hideModal()
    }
  }

  const onDeleteIngredient = async () => {
    try {
      setSubmitting(true)
      await deleteIngredient({ variables: { id: deleteWarning!.id } })
    } catch (error) {
      displayNotification('error', 'Ingredient not deleted', error.message)
    } finally {
      setSubmitting(false)
      setDeleteWarning(null)
    }
  }

  const onCancel = (changed: boolean) => {
    if (changed) {
      setUnsavedChanges(true)
    } else {
      hideModal()
    }
  }

  return (
    <>
      <Switch>
        <Route exact path={path}>
          <IngredientList
            queryIngredientsLoading={queryIngredientsLoading}
            getIngredients={getIngredients}
            handleCreate={() => showModal(null)}
            handleUpdate={ingredient => showModal(ingredient)}
            handleDelete={ingredient => setDeleteWarning(ingredient)}
            ingredients={ingredients}
          />
        </Route>
      </Switch>
      <IngredientCreation
        submitting={submitting}
        initialValues={modalContent}
        visible={modalVisible}
        onCreate={onCreateIngredient}
        onUpdate={onUpdateIngredient}
        onCancel={onCancel}
      />
      <WarningModal
        submitting={submitting}
        visible={!!deleteWarning}
        title="Suppression d'un ingrédient"
        bodyText={`Es-tu sûr de vouloir supprimer ${_.capitalize(
          deleteWarning?.name
        )}`}
        onDismiss={() => setDeleteWarning(null)}
        onConfirm={() => onDeleteIngredient()}
      />
      <WarningModal
        visible={!!unsavedChanges}
        title="Modification en cours"
        bodyText="Toutes les modifications non enregistrées seront perdues."
        onDismiss={() => setUnsavedChanges(false)}
        onConfirm={() => {
          setUnsavedChanges(false)
          hideModal()
        }}
      />
    </>
  )
}

export default Ingredients
