import React from 'react'

const FormGroup: React.FC<{ name: string }> = ({ name, children }) => {
  return (
    <div className="space-y-2">
      <p className="font-body font-bold text-sm text-gray-700 mb-2">{name}</p>
      {children}
    </div>
  )
}

export default FormGroup
