import React from 'react'
import styles from './BlockSubtitle.module.css'

const BlockSubtitle: React.FC<{
  Icon: any
  label: string
  noMargin?: boolean
}> = ({ Icon, label, noMargin = false }) => {
  return (
    <div className={noMargin ? styles.noMarginContainer : styles.container}>
      <div className={styles.iconWrapper}>
        <Icon style={{ width: 26, height: 26 }} />
      </div>
      <p className={styles.subtitle}>{label}</p>
    </div>
  )
}

export default BlockSubtitle
