import React from 'react'
import { IngredientQty } from '../../../types/graphql'
import { capitalize } from 'lodash'
import styles from './IngredientsOverview.module.css'

const IngredientOverview: React.FC<{ items: IngredientQty[] }> = ({
  items
}) => {
  return (
    <div className="flex flex-wrap">
      {items.map(item => (
        <div className={styles.ingredientWrapper} key={item.ingredient.id}>
          <div className={styles.imgWrapper}>
            {item.ingredient.img_url && (
              <img
                loading="lazy"
                className={styles.img}
                crossOrigin="anonymous"
                src={item.ingredient.img_url}
                alt=""
              />
            )}
          </div>
          <span className={styles.itemLabel}>
            {capitalize(item.ingredient.name)}
          </span>
        </div>
      ))}
    </div>
  )
}

export default IngredientOverview
